import { Container, Grid } from "@mui/material";
import { useState } from "react";
import ClientlistCard from "../../components/ClientlistCard"
import { Helmet } from "react-helmet"

function ClientAnalytics() {
  const [searchQuery, setSearchQuery] = useState("")

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value)
  }

  return (
    <div>
      <Helmet>
        <title>Client</title>
      </Helmet>
      <Container className="" maxWidth="lg">
        <Grid container className=" d-flex justify-content-center " spacing={2}>
          <Grid item md={10} sx={{ backgroundColor: "" }} xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <h3 className="mb-0">Client List:</h3>
             
            </div>
          </Grid>
        </Grid>

        <Grid className="d-flex justify-content-center flex-wrap" container>
          <Grid
            md={10}
            xs={12}
            spacing={2}
            className="shadow"
            sx={{ marginTop: 5, padding: 1, borderRadius: 10 }}
          >
            <ClientlistCard searchQuery={searchQuery} />
          </Grid>
        </Grid>
        {/* <Paginate style={{ color: "white" }} count={count}  onChange={handlePageChange}/> */}
      </Container>
    </div>
  )
}
export default ClientAnalytics
