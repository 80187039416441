import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import apiClient from "../api/apiClient"
import { ToastContainer, toast } from "react-toastify"
import { useParams } from "react-router-dom"

function PetBio() {
  const [data, setData] = useState()

  const { id } = useParams()
  //console.log(id,"asasasasa")
  const fetchData = async () => {
    const result = await apiClient.get(`/client/${id}`)
    //console.log(result,'sdsd');
    //   const result = await apiClient.get(`/client/${"64a54b67d64f1b19b5cb93fd"}`);
    if (!result.ok) {
      //console.log('Error:', result.error);
    } else {
      //console.log('Data:', result.data);
      setData(result.data.result)
      //  toast.success('Pet successfully Added');
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (data)
    return (
      <div>
        <Grid container className="d-flex justify-content-center ">
          <Grid className="" item md={10} sx={{ backgroundColor: "" }} xs={12}>
            <Button
              fullWidth
              className=" btn_bg_secondery py-3"
              variant="contained"
              sx={{ whiteSpace: "nowrap" }}
            >
              Pet Bio Feedback Center
            </Button>

            <Grid className=" d-flex justify-content-center">
              <Grid container md={10} xs={12} spacing={2}>
                <Grid container className="mt-4" sx={{ py: 1 }}>
                  <Grid className=" px-2 d-flex" item md={5} xs={12}>
                    <Box>
                      <img src={require("../assets/GPH Logo C_3 animals-01.png")} alt="" />
                    </Box>
                    <Box className="ps-3 fw-bold " sx={{ display: "inline" }}>
                      Pet Bio <br /> Feedback Center
                    </Box>
                  </Grid>
                  <Grid className="px-2" item md={7} xs={12}>
                    <Typography
                      className=""
                      sx={{ textTransform: "uppercase" }}
                      variant="p"
                      color="initial"
                    >
                      Information Sheet
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className=" d-flex justify-content-center">
              <Grid
                className=" "
                container
                md={10}
                xs={12}
                spacing={2}
                component={Paper}
                elevation={5}
                sx={{ marginTop: 1, padding: 5, borderRadius: 7 }}
              >
                <Box className="text-start w-100">
                  <Typography
                    className="fw-bold "
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}
                  >
                    Pet Parent Information:
                  </Typography>
                </Box>
                <Box mt={3}>
                  <hr style={{ backgroundColor: "red" }} />
                </Box>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      First name
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data.firstname}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Last name
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data.lastname}
                    </Typography>
                  </Grid>
                  {/* <Grid item md={4} xs={12}>
                <Typography className='fs_14px ' variant="body2" sx={{ fontWeight:'bold' }}>
                Date
                </Typography>
                <Typography className=' fs_10px' variant="body2" sx={{ fontSize: '0.7rem' }}>
                5/5/23
                </Typography>
                </Grid> */}
                </Grid>

                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Address
                    </Typography>
                    <Typography className="fs_10px" variant="body2" sx={{}}>
                      {data.address}
                    </Typography>
                  </Grid>
                  {/* <Grid item md={4} xs={12}>
                <Typography className=' fs_14px' variant="body2" sx={{ fontWeight:'bold' }}>
                Date
                </Typography>
                <Typography className=' fs_10px' variant="body2" sx={{ }}>
                5/5/2023
                </Typography>
                </Grid> */}
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Country
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data.country}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className=" fs_14px" variant="body2" sx={{ fontWeight: "bold" }}>
                      State
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data.state}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography className=" fs_14px" variant="body2" sx={{ fontWeight: "bold" }}>
                      zip
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data.zip}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Phone number
                    </Typography>
                    <Typography className=" fs_10px" variant="body2" sx={{}}>
                      {data.phone_no}
                    </Typography>
                  </Grid>
                  <Grid sx={{ py: 2 }} item md={6} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Email
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className=" d-flex justify-content-center">
              <Grid
                className=" "
                container
                md={10}
                xs={12}
                spacing={2}
                component={Paper}
                elevation={5}
                sx={{ marginTop: 5, padding: 5, borderRadius: 7 }}
              >
                <Box className="text-start w-100">
                  <Typography
                    className=" fw-bold"
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}
                  >
                    Pet Information:
                  </Typography>
                </Box>
                <Box mt={3}>
                  <hr style={{ backgroundColor: "red" }} />
                </Box>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={4} xs={12}>
                    <Typography className="fs_14px" variant="body2" sx={{ fontWeight: "bold" }}>
                      Barcode
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data?.barcode?.key}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography className="fs_14px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Created Date
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data?.createdAt ? new Date(data.createdAt).toISOString().split("T")[0] : ""}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ py: 2 }}>
                  <Grid item md={4} xs={12}>
                    <Typography className="fs_14px " variant="body2">
                      Pet name
                    </Typography>
                    <Typography className="fs_10px " variant="body2" sx={{}}>
                      {data?.pet?.pet_name}
                      {/* uli */}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography className="fs_14px " variant="body2">
                      Pet Type
                    </Typography>
                    <Typography className=" " variant="body2" sx={{}}>
                      {data?.pet?.type}
                      {/* uli */}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography className="fs_14px " variant="body2">
                      Gender
                    </Typography>
                    <Typography className=" " variant="body2" sx={{}}>
                      {data?.pet?.gender}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={4} xs={12}>
                    <Typography className=" " variant="body2" sx={{ fontSize: "1rem" }}>
                      Birthday
                    </Typography>
                    <Typography className=" " variant="body2" sx={{}}>
                      {data?.pet?.dob}
                      {/* uli */}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography className=" " variant="body2" sx={{ fontSize: "1rem" }}>
                      Age
                    </Typography>
                    <Typography className=" " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data.pet.age}
                      {/* 8 */}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography className=" " variant="body2" sx={{ fontSize: "1rem" }}>
                      Breed
                    </Typography>
                    <Typography className=" " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data.pet.breed}
                      {/* cat */}
                    </Typography>
                  </Grid>
                  <Grid sx={{ py: 2 }} item md={4} xs={12}>
                    <Typography className=" " variant="body2" sx={{ fontSize: "1rem" }}>
                      Birth place
                    </Typography>
                    <Typography className=" " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data.pet.birth_place}
                      {/* us */}
                    </Typography>
                  </Grid>
                  <Grid sx={{ py: 2 }} item md={4} xs={12}>
                    <Typography className=" " variant="body2" sx={{ fontSize: "1rem" }}>
                      Medication
                    </Typography>
                    <Typography className=" " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data.pet.medication}
                      {/* us */}
                    </Typography>
                  </Grid>
                  <Grid sx={{ py: 2 }} item md={4} xs={12}>
                    <Typography className=" " variant="body2" sx={{ fontSize: "1rem" }}>
                      Remarks
                    </Typography>
                    <Typography className=" " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data.pet.remarks}
                      {/* us */}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Typography className='text_color ' variant="body2" sx={{ fontSize: '1rem', textTransform:'uppercase' }}>
                 Pet diet:
                </Typography> */}
                {/* <Grid container sx={{py:2}}>
                <Grid item md={6} xs={12}>
                <FormControlLabel control={<Checkbox  />} label="Dry Kibble:" />
                <FormControlLabel control={<Checkbox  />} label="Raw:" />
                <FormControlLabel control={<Checkbox  />} label="Home Cooked :" />
                </Grid>
             </Grid> */}
                <Typography className="fs_14px " variant="body2" sx={{}}>
                  Agreed With Terms & Conditions.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
}
export default PetBio
