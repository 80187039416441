import { Avatar, Button, CircularProgress, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import apiClient from "../api/apiClient"
import { useDispatch } from "react-redux"
import { logout } from "../redux/counterSlice"
import { useOutletContext } from "react-router-dom"
const Profile = ({ role }) => {
  const [load, setLoad] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const { setRefresh, user } = useOutletContext()
  // console.log(user,"lllll");
  let initialState = {
    profile: "",
    contact: "",
    name: ""
  }
  const [profile, setProfile] = useState(initialState)
  // console.log(profile,"lllll");
  useEffect(() => {
    if (user && role === "AD")
      setProfile({
        contact: user?.data.contact || "",
        name: user?.data.name || ""
      })
  }, [user])

  const handleChange = (key, value) => {
    setProfile({ ...profile, [key]: value })
  }
  const handleSubmit = async e => {
    setLoad(true)
    e.preventDefault()
    // console.log(profile);
    const formdata = new FormData()
    formdata.append("profile", profile.profile)
    if (role == "AD") {
      formdata.append("name", profile.name)
      formdata.append("contact", profile.contact)
    }
    const result = await apiClient.put("/auth/user", formdata)
    setLoad(false)
    setRefresh(true)
    // console.log(result);
  }
  return (
    <div>
      <Grid container className=" d-flex justify-content-center " spacing={2}>
        <Grid item md={10} sx={{ backgroundColor: "" }} xs={12}>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h3 className="mb-0">Update Profile Info:</h3>
          </div>
        </Grid>
      </Grid>

      <div className="d-flex justify-content-center">
        <div className="col-md-5 ">
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
              <div className="col-md-12 p-3 ">
                <div
                  className="d-flex justify-content-between p-2"
                  style={{ backgroundColor: "#EEEEEE", borderRadius: "10px" }}
                >
                  {profile.profile ? (
                    <Avatar
                      src={URL.createObjectURL(profile.profile)}
                      // src={formData.previewImage}
                      alt="Remy Sharp"
                      sx={{ width: 100, height: 100 }}
                    />
                  ) : (
                    <Avatar
                      src={user?.data.profile}
                      // src={formData.previewImage}
                      alt="Remy Sharp"
                      sx={{ width: 100, height: 100 }}
                    />
                  )}
                  <div>
                    <h5 className="">Upload Your Picture</h5>
                    <div className="d-flex gap-2">
                      <input
                        required
                        type="file"
                        onChange={e => handleChange("profile", e.target.files[0])}
                        accept="image/*"
                      />
                    </div>
                    <p className=" fs_14px">less then 1MB Picture Size</p>
                  </div>
                </div>

                {role === "AD" && (
                  <div>
                    <div class="equal-width py-1">
                      <label for="" class="form-label white_space">
                        Name:{" "}
                      </label>
                      <div>
                        <input
                          required
                          type="text"
                          value={profile.name}
                          onChange={e => handleChange("name", e.target.value)}
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                        />
                      </div>
                    </div>

                    <div class="py-1 ">
                      <label for="" class="form-label white_space">
                        Phone Number:{" "}
                      </label>
                      <div class=" mb-3">
                        <input
                          required
                          type="number"
                          value={profile.contact}
                          onChange={e => handleChange("contact", e.target.value)}
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <Button onClick={() => setModalShow(true)}>Change Password</Button>
                    </div>
                  </div>
                )}
              </div>
              <div></div>
            </div>
            {!load ? (
              <div className="px-3">
                <button type="submit" class="btn btn_bg_secondery px-4 text-white">
                  Save
                </button>
              </div>
            ) : (
              <div className=" w-100 d-flex justify-content-center align-items-center">
                <CircularProgress />
              </div>
            )}
          </form>
        </div>
      </div>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  )
}

export default Profile

function MyVerticallyCenteredModal(props) {
  const dispatch = useDispatch()
  let initialState = {
    current: "",
    newpassword: "",
    rePassword: ""
  }
  const [password, setPassword] = useState(initialState)
  const handleChange = (key, value) => {
    setPassword({ ...password, [key]: value })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (password.newpassword != password.rePassword) {
      return console.log("object")
    }

    const result = await apiClient.patch("/auth/updateMyPassword", {
      passwordCurrent: password.current,
      password: password.newpassword
    })
    // console.log(result);
    props.onHide()
    dispatch(logout())
  }
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div class="py-1 ">
            <label for="" class="form-label white_space">
              Current Password{" "}
            </label>
            <div class=" mb-3">
              <input
                required
                type="password"
                value={password.current}
                onChange={e => handleChange("current", e.target.value)}
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>
          </div>
          <div class="py-1 ">
            <label for="" class="form-label white_space">
              Enter Password{" "}
            </label>
            <div class=" mb-3">
              <input
                required
                type="password"
                value={password.newpassword}
                onChange={e => handleChange("newpassword", e.target.value)}
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>
          </div>

          <div class="py-1 ">
            <label for="" class="form-label white_space">
              Reenter Password{" "}
            </label>
            <div class=" mb-3">
              <input
                required
                type="password"
                value={password.rePassword}
                onChange={e => handleChange("rePassword", e.target.value)}
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Save</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
