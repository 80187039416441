import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

export default function ({ children }) {
  const { isLoggedIn } = useSelector(state => state.auth)

  if (!isLoggedIn) {
    // user is not authenticated
    return <Navigate replace to={"/"} />
  }
  return children
}
