import React, { useState } from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import SearchIcon from "@mui/icons-material/Search"

const SearchHome = ({
  defaultLabel,
  setSearch,
  onSearch,
  search,
  setKeyword,
  keyword,
  data,
  style = {}
}) => {
  const [label, setLabel] = useState("")

  return (
    <Box style={{ display: "flex", gap: "10px", width: "100%", height: "35px" }}>
      <Box style={{ display: "flex", position: "relative" }}>
        <Paper
          sx={{
            p: "2px",
            display: "flex",
            alignItems: "center",
            height: "35px", // Set the same height for the Paper component
            width: style.width
          }}
        >
          <Select
            id="demo-simple-select"
            value={keyword}
            onChange={(event, value) => {
              // console.log(event.target.value, value.props);
              setLabel(value.props.children)
              setKeyword(event.target.value)
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "100%" }}
          >
            {data.map((item, index) => (
              <MenuItem key={index} value={item.route}>
                {item.name}
              </MenuItem>
            ))}
          </Select>

          <InputBase
            sx={{ ml: 1, flex: 1, height: "100%", lineHeight: "35px" }}
            placeholder={`Search by ${label || defaultLabel || data[0].name}`}
            onChange={({ currentTarget: input }) => setSearch(input.value)}
            value={search}
          />
          {/* <SearchIcon
        sx={{ position: 'absolute', right: 5, cursor: 'pointer' }}
        onClick={onSearch}
      /> */}
        </Paper>
      </Box>
    </Box>
  )
}

export default SearchHome
