import * as React from "react"
import { styled, alpha } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import EditIcon from "@mui/icons-material/Edit"
import Divider from "@mui/material/Divider"
import ArchiveIcon from "@mui/icons-material/Archive"
import PictureAsPdf from "@mui/icons-material/PictureAsPdf"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Modal } from "react-bootstrap"
import { Grid, TextField } from "@mui/material"
import SelectOption from "../mui/SelectOption"

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    }
    // '& .MuiMenuItem-root': {
    //   '& .MuiSvgIcon-root': {
    //     fontSize: 18,
    //     color: theme.palette.text.secondary,
    //     marginRight: theme.spacing(1.5),
    //   },
    //   '&:active': {
    //     backgroundColor: alpha(
    //       theme.palette.primary.main,
    //       theme.palette.action.selectedOpacity,
    //     ),
    //   },
    // },
  }
}))

export default function BarcodesExportMenu({ handleDownload }) {
  const [show, setShow] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onHide = () => {
    setExportType("")
    setBarcodeQuantity(0)
  }

  const [barcodeType, setBarcodeType] = React.useState("")
  const [barcodeQuantity, setBarcodeQuantity] = React.useState(0)
  const [exportType, setExportType] = React.useState("")
  const handleExport = (e) => {
    e.preventDefault()
    handleDownload({ type: exportType, quantity: barcodeQuantity, barcodeType })
    setBarcodeQuantity(0)
  }
  return (
    <div>
      <Button
        id="demo-customized-button"
        className="btn btn_bg_secondery"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}>
        Export
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            setExportType("csv")
          }}
          disableRipple>
          {/* <EditIcon /> */}
          <i class="bi bi-filetype-csv me-2" style={{ fontSize: 30 }}></i>
          CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            setExportType("pdf")
          }}
          disableRipple>
          <i class="bi bi-filetype-pdf me-2" style={{ fontSize: 30 }}></i>
          {/* <PictureAsPdf /> */}
          PDF
        </MenuItem>
      </StyledMenu>

      <Modal
        show={!!exportType}
        onHide={onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100 ms-4">
        </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <form className="" onSubmit={handleExport}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectOption
                  style={{ width: "100%" }}
                  size="small"
                  data={[
                    { value: "!pp", label: "Normal" },
                    { value: "pp", label: "PP Barcodes" },
                    { value: "exp", label: "EXP Barcodes" }
                  ]}
                  id="outlined-basic"
                  value={barcodeType}
                  onChange={e => setBarcodeType(e.target.value)}
                  label="Barcode Type"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  value={barcodeQuantity}
                  onChange={e => setBarcodeQuantity(e.target.value)}
                  style={{ width: "100%" }}
                  size="small"
                  type="number"
                  InputProps={{
                    inputProps: exportType === "pdf" ? { min: 1, max: 100 } : { min: 1 }
                  }}
                />
              </Grid>

              <Grid item xs={12} className="d-flex justify-content-center">
                <Button
                  id="demo-customized-button-export"
                  className="btn btn_bg_secondery"
                  variant="contained"
                  type="submit">
                  {exportType === "csv" ? "Export CSV" : "Export PDF"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
