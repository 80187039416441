import { Grid, FormControlLabel, Checkbox } from "@mui/material"
import { Box, maxHeight } from "@mui/system"
import React, { useEffect, useState } from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import useApi from "../hooks/useApi"
import apiClient from "../api/apiClient"

export const Products = ({ data, products, downloadMode }) => {
  const [expandedCard, setExpandedCard] = useState(null)
  const handleReadMoreClick = cardId => {
    // console.log(cardId,"sa");
    setExpandedCard(cardId === expandedCard ? null : cardId)
  }

  return (
    <div>
      <Box className="" sx={{ backgroundColor: "white" }}>
        <div className="break-avoid d-flex justify-content-start  px-2 flex-wrap ">
          <div className="col-12 py-3">
            <div className="" style={{ width: "100%", backgroundColor: "#716879" }}>
              <p className="text-white px-3 mb-0 py-3">
                Based on your results, the items check marked green may be beneficial, while items
                marked with a red “X” are not suitable at this time
              </p>
            </div>
          </div>
          <div className="break-avoid p-3 col-md-3 col-sm-12 ">
            <h6>Glacier Peak Holistics</h6>
            <p className="">
              Each product in the Glacier Peak Holistics family has been crafted to support the
              health and well-being of your four footed friends and to ensure their best quality of
              life, without compromising longevity.
            </p>
            {/* <p className=''>
            With over 20 years’ experience
            working with herbs, homoeopathic,
            and nutritional, we’ve created
            exceptional products that will boost
            your pet’s health and help relieve pain
            caused by injury, aging or disease. All
            of our products are handcrafted and
            manufactured in our facility, here in
            the beautiful mountains of Northwest
            Montana.
            </p>
            <p className=''>
            We use only natural, 100% organic
            herbs, primarily sourced in the
            Northwest United States.
            </p> */}
          </div>

          {data?.slice(0, 3).map(({ status, product: card }, index) => {
            return (
              <div
                className={"break-avoid col-md-3 col-sm-12 d-flex align-items-strech mb-3 "}
                key={card.id}
              >
                <div
                  class="card "
                  style={{ border: "1px solid #716879 rounded-0 ", width: "20rem" }}
                >
                  <div
                    className=""
                    style={{ border: "3px solid #F09C05", backgroundColor: "#D4D8E4" }}
                  >
                    <div class="">
                      <label for={`myCheckbox0${index}`} class=" checkbox p-1">
                        <input
                          class="checkbox__input"
                          type="checkbox"
                          id={`myCheckbox0${index}`}
                          checked={true}
                          style={{ margin: "1rem" }}
                        />
                        {!status && (
                          <svg
                            class="checkbox__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <rect
                              width="21"
                              height="21"
                              x=".5"
                              y=".5"
                              fill="#FFF"
                              stroke="#006F94"
                              rx="3"
                            />
                            <path
                              class="tick"
                              stroke="#fff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              d="M4 10l5 5 9-9"
                            />
                          </svg>
                        )}
                        {status === "checked" && (
                          <svg
                            class="checkbox__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <rect
                              width="21"
                              height="21"
                              x=".5"
                              y=".5"
                              fill="#FFF"
                              stroke="#006F94"
                              rx="3"
                            />
                            <path
                              class="tick"
                              stroke="#6EA340"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              d="M4 10l5 5 9-9"
                            />
                          </svg>
                        )}

                        {status === "unchecked" && (
                          <svg
                            class="checkbox__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <rect
                              width="21"
                              height="21"
                              x=".5"
                              y=".5"
                              fill="#FFF"
                              stroke="#FF0000"
                              rx="3"
                            />
                            <line
                              class="cross"
                              stroke="#FF0000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              x1="5"
                              y1="5"
                              x2="17"
                              y2="17"
                            />
                            <line
                              class="cross"
                              stroke="#FF0000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              x1="17"
                              y1="5"
                              x2="5"
                              y2="17"
                            />
                          </svg>
                        )}
                      </label>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <div className="" style={{ width: "10rem", height: "10rem" }}>
                        <img src={card.image} className="img-fluid" alt="img" />
                      </div>
                    </div>
                  </div>
                  <div class="card-body" style={{ backgroundColor: "#F9F5F2" }}>
                    <h6 style={{ color: "#847267" }}>{card.name} :</h6>
                    {/* <p class="card-text fs_14px">{expandedCard === card._id?card.description:card.description.slice(0, 100)+"..."} 
                          <span style={{color:"blue",cursor:"pointer"}}
                          onClick={()=>{
                            handleReadMoreClick(card._id)
                            }}>
                            {expandedCard === card._id ? 'Read Less' : 'Read More'}</span>
                      </p>   */}
                    <p>{card.description.slice(0, 100) + "..."}</p>
                  </div>

                  <div className="d-flex justify-content-start p-2">
                    <a
                      href={card.link}
                      target="blank"
                      className="decoration-none d-flex gap-2 btn rounded-0 text-white px-4"
                      style={{ backgroundColor: "#F09C05" }}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className=" d-flex justify-content-start  px-2 flex-wrap ">
          {data?.slice(3).map(({ status, product: card }, index) => {
            return (
              <div
                className={"break-avoid col-md-3 col-sm-12 d-flex align-items-strech mb-3 "}
                key={card.id}
              >
                <div
                  class="card "
                  style={{ border: "1px solid #716879 rounded-0 ", width: "20rem" }}
                >
                  <div
                    className=""
                    style={{ border: "3px solid #F09C05", backgroundColor: "#D4D8E4" }}
                  >
                    <div class="">
                      <label for={`myCheckbox0${index}`} class=" checkbox p-1">
                        <input
                          class="checkbox__input"
                          type="checkbox"
                          id={`myCheckbox0${index}`}
                          checked={true}
                          style={{ margin: "1rem" }}
                        />
                        {!status && (
                          <svg
                            class="checkbox__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <rect
                              width="21"
                              height="21"
                              x=".5"
                              y=".5"
                              fill="#FFF"
                              stroke="#006F94"
                              rx="3"
                            />
                            <path
                              class="tick"
                              stroke="#fff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              d="M4 10l5 5 9-9"
                            />
                          </svg>
                        )}
                        {status === "checked" && (
                          <svg
                            class="checkbox__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <rect
                              width="21"
                              height="21"
                              x=".5"
                              y=".5"
                              fill="#FFF"
                              stroke="#006F94"
                              rx="3"
                            />
                            <path
                              class="tick"
                              stroke="#6EA340"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              d="M4 10l5 5 9-9"
                            />
                          </svg>
                        )}

                        {status === "unchecked" && (
                          <svg
                            class="checkbox__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <rect
                              width="21"
                              height="21"
                              x=".5"
                              y=".5"
                              fill="#FFF"
                              stroke="#FF0000"
                              rx="3"
                            />
                            <line
                              class="cross"
                              stroke="#FF0000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              x1="5"
                              y1="5"
                              x2="17"
                              y2="17"
                            />
                            <line
                              class="cross"
                              stroke="#FF0000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-width="4"
                              x1="17"
                              y1="5"
                              x2="5"
                              y2="17"
                            />
                          </svg>
                        )}
                      </label>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <div className="" style={{ width: "10rem", height: "10rem" }}>
                        <img src={card.image} className="img-fluid" alt="img" />
                      </div>
                    </div>
                  </div>
                  <div class="card-body" style={{ backgroundColor: "#F9F5F2" }}>
                    <h6 style={{ color: "#847267" }}>{card.name} :</h6>
                    {/* <p class="card-text fs_14px">{expandedCard === card._id?card.description:card.description.slice(0, 100)+"..."} 
                        <span style={{color:"blue",cursor:"pointer"}}
                        onClick={()=>{
                          handleReadMoreClick(card._id)
                          }}>
                          {expandedCard === card._id ? 'Read Less' : 'Read More'}</span>
                    </p>   */}
                    <p>{card.description.slice(0, 100) + "..."}</p>
                  </div>

                  <div className="d-flex justify-content-start p-2">
                    <a
                      href={card.link}
                      target="blank"
                      className="decoration-none d-flex gap-2 btn rounded-0 text-white px-4"
                      style={{ backgroundColor: "#F09C05" }}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Box>
    </div>
  )
}

export const ReportProducts = ({ products, handleProducts, setProducts }) => {
  useEffect(() => {
    fetchData()
  }, [])
  const { request, data, error, loading } = useApi(() =>
    apiClient.get("/report/product/?limit=10000")
  )

  async function fetchData() {
    const result = await request()
    if (!result.ok) return
    let data = result.data.results

    //console.log(result);
  }

  const [words, setwords] = useState(true)
  const [expandedCard, setExpandedCard] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const handleReadMoreClick = cardId => {
    // console.log(cardId,"sa");
    setExpandedCard(cardId === expandedCard ? null : cardId)
  }

  return (
    <div>
      <Box className=" px-3" sx={{ maxWidth: "100%", width: "100%", backgroundColor: "#716879" }}>
        {/* <p className=' fs_10px text-white mb-0 px-4'>
          Understanding your result
          </p> */}
        {/* <p className="text-white px-3 mb-0" style={{ backgroundColor: "#716879", fontSize: "2rem" }}>
          Recommended Products.
          </p> */}
        <p className="text-white px-3 mb-0 py-3">
          Based on your results, the items check marked green may be beneficial, while items marked
          with a red “X” are not suitable at this time
        </p>
      </Box>
      <Box className="" sx={{ backgroundColor: "white" }}>
        <div className="gx-2 row p-2 ">
          <div className="  col-md-3 col-sm-12 d-flex justify-content-center">
            <div className=" col-md-8 p-3" style={{ backgroundColor: "#F9F5F2", height: "auto" }}>
              <h6 style={{ color: "#A7988B" }}>Glacier Peak Holistics</h6>
              <p className="fs_14px" style={{ color: "#847267" }}>
                Each product in the Glacier Peak Holistics family has been crafted to support the
                health and well-being of your four footed friends and to ensure their best quality
                of life, without compromising longevity.
              </p>
              {/* <p className='fs_14px' style={{color:"#847267"}}>
          With over 20 years’ experience
          working with herbs, homoeopathic,
          and nutritional, we’ve created
          exceptional products that will boost
          your pet’s health and help relieve pain
          caused by injury, aging or disease. All
          of our products are handcrafted and
          manufactured in our facility, here in
          the beautiful mountains of Northwest
          Montana.
          </p>
          <p className='fs_14px' style={{color:"#847267"}}>
          We use only natural, 100% organic
          herbs, primarily sourced in the
          Northwest United States.
          </p> */}
            </div>
          </div>
          {data?.map((card, index) => {
            const status = products?.find(x => x.product === card._id)?.status

            const limitWords = (text, limit) => {
              // console.log(text,"sasasa");
              if (expandedCard === card._id) {
                const words = text.description.split(" ")
                const limitedWords = words.slice(0, limit)
                return limitedWords.join(" ")
              }
            }

            const limitedDescription = limitWords(card, words ? 30 : 1000)
            return (
              <div className=" col-md-3 col-sm-12" key={card.id}>
                <div className="d-flex justify-content-center">
                  <div
                    class="card "
                    style={{
                      border: "1px solid #716879 rounded-0 ",
                      width: "20rem",
                      height: "auto"
                    }}
                  >
                    <div
                      className=""
                      style={{
                        border: "2px solid #F09C05",
                        backgroundColor: "#D4D8E4",
                        height: "25vh"
                      }}
                    >
                      <div class="">
                        <label for={`myCheckbox0${index}`} class=" checkbox p-1">
                          <input
                            class="checkbox__input"
                            type="checkbox"
                            id={`myCheckbox0${index}`}
                            checked={true}
                            // checked={exist}
                            onChange={e => {
                              // setIsChecked(e.target.checked);
                              handleProducts(card._id) // Call handleProducts function when checkbox is clicked
                            }}
                            style={{ margin: "1rem" }}
                          />
                          {!status && (
                            <svg
                              class="checkbox__icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 22 22"
                            >
                              <rect
                                width="21"
                                height="21"
                                x=".5"
                                y=".5"
                                fill="#FFF"
                                stroke="#006F94"
                                rx="3"
                              />
                              <path
                                class="tick"
                                stroke="#fff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-width="4"
                                d="M4 10l5 5 9-9"
                              />
                            </svg>
                          )}
                          {status === "checked" && (
                            <svg
                              class="checkbox__icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 22 22"
                            >
                              <rect
                                width="21"
                                height="21"
                                x=".5"
                                y=".5"
                                fill="#FFF"
                                stroke="#006F94"
                                rx="3"
                              />
                              <path
                                class="tick"
                                stroke="#6EA340"
                                fill="none"
                                stroke-linecap="round"
                                stroke-width="4"
                                d="M4 10l5 5 9-9"
                              />
                            </svg>
                          )}

                          {status === "unchecked" && (
                            <svg
                              class="checkbox__icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 22 22"
                            >
                              <rect
                                width="21"
                                height="21"
                                x=".5"
                                y=".5"
                                fill="#FFF"
                                stroke="#FF0000"
                                rx="3"
                              />
                              <line
                                class="cross"
                                stroke="#FF0000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-width="4"
                                x1="5"
                                y1="5"
                                x2="17"
                                y2="17"
                              />
                              <line
                                class="cross"
                                stroke="#FF0000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-width="4"
                                x1="17"
                                y1="5"
                                x2="5"
                                y2="17"
                              />
                            </svg>
                          )}
                        </label>
                      </div>
                      <div className=" d-flex justify-content-center ">
                        <div style={{ width: "12rem" }}>
                          <img src={card.image} className="card-img-top img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                    <div class=" card-body" style={{ backgroundColor: "#F9F5F2" }}>
                      <h6 style={{ color: "#847267" }}>{card.name} :</h6>
                      <p class="card-text fs_14px">
                        {expandedCard === card._id
                          ? card.description
                          : card.description.slice(0, 100) + "..."}
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            handleReadMoreClick(card._id)
                          }}
                        >
                          {expandedCard === card._id ? "Read Less" : "Read More"}
                        </span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      {/* <button className='py-0 btn btn_bg_secondery'>{card.name}</button> */}
                    </div>
                  </div>
                </div>
                <div className="py-3 d-flex justify-content-center ">
                  {/* <button onClick={()=>handleProducts(card._id)}  className={`border-left d-flex gap-2 btn ${!exist?" btn-success":"btn-danger"} `}>
                  {exist?"Remove":"Add"}
                  </button> */}
                </div>
              </div>
            )
          })}
        </div>
      </Box>
    </div>
  )
}
