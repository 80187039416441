export default {
  primary: "#005e9d",
  secondary: "#AFB7BE",
  black: "#000",
  white: "#fff",
  medium: "#6e6969",
  light: "#D6D6D6",
  dark: "#0c0c0c",
  skin: "#cfdde6",
  danger: "#ff5252",
  blue: "lightblue",
  transparent: "transparent"
}

const layoutColors = {
  SystemDashboard: {
    primary: "#800020",
    secondary: "#fffff",
    tertiary: "#C24E6E"
  },
  AdminDashboard: {
    primary: "#007bff",
    secondary: "#fffff",
    tertiary: "#cce5ff"
  },
  HospitalAdmin: {
    primary: "#28a745",
    secondary: "#fffff",
    tertiary: "#d4edda"
  }
}

export { layoutColors }
