import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import Autocomplete from "@mui/material/Autocomplete"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import BackButton from "../../components/mui/BackButton"
import Modal from "react-bootstrap/Modal"
import TableMui from "../../components/mui/TableMui"
import apiClient from "../../api/apiClient"
import { useDispatch } from "react-redux"
import { showModal } from "../../redux/modalSlice"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import SearchIcon from "@mui/icons-material/Search"
import DirectionsIcon from "@mui/icons-material/Directions"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const AdminReports = () => {
  const [value, setValue] = useState(0)
  const [categoryData, setCategoryData] = useState()
  const [categoryFilterData, setCategoryFilterData] = useState()
  const [itemFilterData, setItemFilterData] = useState()
  const [ifFilterData, setIfFilterData] = useState()
  const [itemData, setItemData] = useState()
  const [itemDataEdit, setItemDataEdit] = useState()
  const [search, setSearch] = useState()
  const [ifthenData, setIfthenData] = useState([])
  const [ifthenProductData, setIfthenProductData] = useState([])
  const [engRefProductData, setEngRefProductData] = useState([])
  const [ifProductFilterData, setIfProductFilterData] = useState()
  const [engRefProductFilterData, setEngRefProductFilterData] = useState()
  const [loadIfProduct, setLoadIfProduct] = useState(false)
  const [productData, setProductData] = useState()
  const [productEdit, setProductEdit] = useState()

  const [categoryEdit, setCategoryEdit] = useState()
  const [ifthenEdit, setifthenEdit] = useState()
  const [ifthen, setIfthen] = useState(false)
  const [ifthenProductEdit, setifthenProductEdit] = useState()
  const [ifthenProductModal, setIfthenPModal] = useState(false)
  const [engRefProductEdit, setEngRefProductEdit] = useState()
  const [engRefProductModal, setEngRefPModal] = useState(false)
  const [keyword, setKeyword] = useState("item")
  const [category, setCategory] = useState(false)
  const [item, setItem] = useState(false)

  const [product, setProduct] = useState(false)

  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setSearch("")
  }

  const fetchData = async () => {
    setLoad(true)
    const result = await apiClient.get("/report/category?sort=name")
    if (!result.ok) return console.log("error")
    setCategoryData(result.data.results)
    setCategoryFilterData(result.data.results)
    setLoad(false)
    // console.log(result.data.results,"result");
  }
  const fetchItemData = async () => {
    setLoad(true)
    const result = await apiClient.get("/report/item?limit=100000000&sort=name")
    if (!result.ok) return console.log("error")
    setLoad(false)
    setItemData(result.data.results)
    setItemFilterData(result.data.results)
    // console.log(result,"result2");
  }

  const fetchifthenData = async () => {
    setLoad(true)
    const result = await apiClient.get("/if-then?populate=true&limit=100000000")
    if (!result.ok) return console.log("error")
    setLoad(false)
    const sorted = result.data.results.sort(function (a, b) {
      if (a.itemId.name < b.itemId.name) {
        return -1
      }
      if (a.itemId.name > b.itemId.name) {
        return 1
      }
      return 0
    })
    setIfthenData(sorted)
    setIfFilterData(sorted)
    // console.log(result,"ifthen res");
  }
  const fetchifthenProductData = async () => {
    setLoad(true)
    const result = await apiClient.get("/if-then/products/?populate=true&limit=100000000")
    if (!result.ok) return console.log("error")
    setLoad(false)
    const sorted = result.data.results.sort(function (a, b) {
      if (a.itemId.name < b.itemId.name) {
        return -1
      }
      if (a.itemId.name > b.itemId.name) {
        return 1
      }
      return 0
    })
    setIfthenProductData(sorted)
    setIfProductFilterData(sorted)
    // console.log(result,"ifthen res");
  }

  const fetchEnerRefProductData = async () => {
    setLoad(true)
    const result = await apiClient.get("/if-then/energetic/?populate=true&limit=100000000")
    if (!result.ok) return console.log("error")
    setLoad(false)
    const sorted = result.data.results.sort(function (a, b) {
      if (a.energetic_imbalance < b.energetic_imbalance) {
        return -1
      }
      if (a.energetic_imbalance > b.energetic_imbalance) {
        return 1
      }
      return 0
    })
    setEngRefProductData(sorted)
    setEngRefProductFilterData(sorted)
    // console.log(result,"ifthen res");
  }

  const fetchproduct = async () => {
    setLoad(true)
    const result = await apiClient.get("/report/product")
    if (!result.ok) return console.log("error")
    setProductData(result.data.results)
    // setCategoryFilterData(result.data.results)
    setLoad(false)
    // console.log(result.data.results,"result");
  }

  // console.log(ifthenData, "ifthenData")

  const remove = async id => {
    const result = await apiClient.delete(`/report/category/${id}`)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    fetchData()
    fetchItemData()
    fetchifthenData()
  }

  const removeItem = async id => {
    const result = await apiClient.delete(`/report/item/${id}`)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    fetchItemData()
    fetchData()
    fetchifthenData()
  }
  const removeIfThen = async id => {
    const result = await apiClient.delete(`/if-then/${id}`)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    fetchifthenData()
    fetchData()
    fetchItemData()
  }
  const removeIfThenProduct = async id => {
    const result = await apiClient.delete(`/if-then/products/${id}`)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    fetchifthenProductData()
  }

  const removeEnerRefProduct = async id => {
    const result = await apiClient.delete(`/if-then/energetic/${id}`)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    fetchEnerRefProductData()
  }

  const removeProduct = async id => {
    const result = await apiClient.delete(`/report/product/${id}`)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    fetchifthenData()
    fetchData()
    fetchItemData()
    fetchproduct()
  }

  useEffect(() => {
    fetchData()
    fetchItemData()
    fetchifthenData()
    fetchproduct()
    fetchifthenProductData()
   fetchEnerRefProductData()
  }, [])

  const filterData = value => {
    const filteredData = categoryFilterData.filter(item => {
      return item.name.toLowerCase().includes(value.toLowerCase())
    })

    // console.log(filteredData,"opo");
    setCategoryData(filteredData)
  }
  const filterItem = value => {
    if (keyword === "item") {
      const filteredData = itemFilterData.filter(item => {
        return item.name.toLowerCase().includes(value.toLowerCase())
      })
      setItemData(filteredData)
    } else if (keyword === "category") {
      // console.log("obj");
      const filteredData = itemFilterData.filter(item => {
        return item.category.name.toLowerCase().includes(value.toLowerCase())
      })
      setItemData(filteredData)
    }
  }
  const filterIfThen = value => {
    const filteredData = ifFilterData.filter(item => {
      return item.itemId?.name.toLowerCase().includes(value.toLowerCase())
      // console.log(item.itemId?.name,"oiu");
    })

    // console.log(filteredData,"opo");
    setIfthenData(filteredData)
  }
  // console.log(keyword,"iopkl");

  return (
    <div className="container">
      <Helmet>
        <title>Reports Data</title>
      </Helmet>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            indicatorColor="red"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab
              style={{
                color: value === 0 ? "white" : "black",
                backgroundColor: value === 0 ? "#3E4755" : "transparent"
              }}
              label="Add Category"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                color: value === 1 ? "white" : "black",
                backgroundColor: value === 1 ? "#3E4755" : "transparent"
              }}
              label="Add Item"
              {...a11yProps(1)}
            />
            <Tab
              style={{
                color: value === 2 ? "white" : "black",
                backgroundColor: value === 2 ? "#3E4755" : "transparent"
              }}
              label="If Then"
              {...a11yProps(2)}
            />
            <Tab
              style={{
                color: value === 3 ? "white" : "black",
                backgroundColor: value === 3 ? "#3E4755" : "transparent"
              }}
              label="Product"
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid container>
            <Grid item xs={6} className="p-3 d-flex justify-content-end">
              <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search " }}
                  onKeyUp={e => e.key === "Enter" && filterData(search)}
                />
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                  <SearchIcon onClick={() => filterData(search)} />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={6} className="p-3 d-flex justify-content-end">
              <Button
                className="btn btn_bg_ter"
                variant="contained"
                onClick={() => {
                  setCategoryEdit(false)
                  setCategory(true)
                }}>
                Add Category
              </Button>
            </Grid>
            {!load ? (
              <TableMui
                styleTableContainer={{ height: "70vh" }}
                styleTableTh={{
                  color: "white",
                  backgroundColor: "#3E4755",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                }}
                th={{
                  name: "Category",
                  actions: "Action"
                }}
                td={categoryData}
                customFields={[
                  {
                    name: "actions",
                    data: (value, item) => (
                      <Box className="d-flex  gap-3">
                        <Button
                          className="btn btn_bg_ter"
                          variant="contained"
                          color="info"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            setCategoryEdit(item)
                            setCategory(true)
                          }}>
                          Edit
                        </Button>

                        <Button
                          className="btn btn_bg_ter"
                          variant="contained"
                          color="error"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            remove(item._id)
                          }}>
                          Delete
                        </Button>
                      </Box>
                    )
                  }
                ]}
              />
            ) : (
              <div className=" w-100 d-flex justify-content-center align-items-center">
                <CircularProgress />
              </div>
            )}
          </Grid>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Grid container className=" d-flex justify-content-center align-items-center">
            <Grid container>
              <Grid item xs={6} className="p-3 d-flex justify-content-end">
                <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search " }}
                    onKeyUp={e => e.key === "Enter" && filterItem(search)}
                  />
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon onClick={() => filterItem(search)} />
                  </IconButton>
                </Paper>
                <FormControl className="">
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={keyword}
                    label="Select"
                    onChange={e => setKeyword(e.target.value)}>
                    <MenuItem value={"item"}>Item</MenuItem>
                    <MenuItem value={"category"}>Category</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className="p-3 d-flex justify-content-end">
                <Button
                  className="btn btn_bg_ter"
                  variant="contained"
                  onClick={() => {
                    setItemDataEdit(false)
                    setItem(true)
                  }}>
                  Add Item
                </Button>
              </Grid>
              {!load ? (
                <TableMui
                  styleTableContainer={{ height: "70vh" }}
                  styleTableTh={{
                    color: "white",
                    backgroundColor: "#3E4755",
                    fontWeight: "bold",
                    whiteSpace: "nowrap"
                  }}
                  th={{
                    name: "Item",
                    "category.name": "Category",
                    actions: "Action"
                  }}
                  td={itemData}
                  customFields={[
                    {
                      name: "actions",
                      data: (value, item) => (
                        <Box className="d-flex  gap-3">
                          <Button
                            className="btn btn_bg_ter"
                            variant="contained"
                            color="info"
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                              setItemDataEdit(item)
                              setItem(true)
                            }}>
                            Edit
                          </Button>
                          <Button
                            className="btn btn_bg_ter"
                            variant="contained"
                            color="error"
                            style={{ textDecoration: "none" }}
                            onClick={() => removeItem(item._id)}>
                            Delete
                          </Button>
                        </Box>
                      )
                    }
                  ]}
                />
              ) : (
                <div className="w-100 d-flex justify-content-center slign-items-center">
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </Grid>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          {/* <Button onClick={()=>setIfthen(true)}>dsds</Button> */}
          <Grid  className=" ">
            <Box className="mt-5">
              <Typography variant="h6">If-Then Items</Typography>
              <Grid container>
                <Grid item xs={6} className="p-3 d-flex">
                  <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search " }}
                      onKeyUp={e => e.key === "Enter" && filterIfThen(search)}
                    />
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                      <SearchIcon onClick={() => filterIfThen(search)} />
                    </IconButton>
                  </Paper>
                </Grid>
                <Grid item xs={6} className="p-3 d-flex justify-content-end">
                  <Button
                    className="btn btn_bg_ter"
                    variant="contained"
                    onClick={() => {
                      setItemDataEdit(false)
                      setIfthen(true)
                    }}>
                    Add Item
                  </Button>
                </Grid>
                {!load ? (
                  <TableMui
                    styleTableContainer={{ height: "70vh" }}
                    styleTableTh={{
                      color: "white",
                      backgroundColor: "#3E4755",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                    th={{
                      "itemId.name": "Category",
                      ifthen: "If-Then",
                      actions: "Action"
                    }}
                    td={ifthenData}
                    customFields={[
                      {
                        name: "ifthen",
                        data: (value, item) => item.items.map(item => item.name).join(",")
                      },
                      {
                        name: "actions",
                        data: (value, item) => (
                          <Box className="d-flex  gap-3">
                            <Button
                              className="btn btn_bg_ter"
                              variant="contained"
                              color="info"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                setIfthen(true)
                                setifthenEdit(item)
                              }}>
                              Edit
                            </Button>

                            <Button
                              className="btn btn_bg_ter"
                              variant="contained"
                              color="error"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                removeIfThen(item._id)
                              }}>
                              Delete
                            </Button>
                          </Box>
                        )
                      }
                    ]}
                  />
                ) : (
                  <div className="w-100 d-flex justify-content-center slign-items-center">
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Box>
            <Box className="mt-5">
              <Typography variant="h6">If-Then Products</Typography>
              <Grid container>
                {/* <Grid item xs={6} className="p-3 d-flex justify-content-end">
                  <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search " }}
                      onKeyUp={e => e.key === "Enter" && filterIfThen(search)}
                    />
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                      <SearchIcon onClick={() => filterIfThen(search)} />
                    </IconButton>
                  </Paper>
                </Grid> */}
                <Grid item xs={12} className="p-3 d-flex justify-content-end">
                  <Button
                    className="btn btn_bg_ter"
                    variant="contained"
                    style={{paddingTop:12,paddingBottom:12}}
                    onClick={() => {
                      setIfthenPModal(true)
                    }}>
                    Add Product
                  </Button>
                </Grid>
                {!load ? (
                  <TableMui
                    styleTableContainer={{ height: "70vh" }}
                    styleTableTh={{
                      color: "white",
                      backgroundColor: "#3E4755",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                    th={{
                      "itemId.name": "Item Name",
                      item_senstivity: "Sensitivity",
                      "product.name": "Product",
                      product_recommended: "Product Recomented",
                      pet_type: "Pet Type",
                      actions: "Action"
                    }}
                    td={ifthenProductData}
                    customFields={[
                      {
                        name: "product_recommended",
                        data: (value, item) =>
                          value ? (
                            <Chip
                              label="Recommended"
                              style={{ backgroundColor: "green", color: "white" }}
                            />
                          ) : (
                            <Chip
                              label="Not-Recommended"
                              style={{ backgroundColor: "red", color: "white" }}
                            />
                          )
                      },
                      {
                        name: "item_senstivity",
                        data: (value, item) =>
                          value === "HG" ? (
                            <Chip label="High" style={{ backgroundColor: "Red", color: "white" }} />
                          ) : (
                            <Chip
                              label="Medium"
                              style={{ backgroundColor: "yellow", color: "black" }}
                            />
                          )
                      },
                      {
                        name: "pet_type",
                        data: (value, item) => value?.toUpperCase() || "All"
                      },
                      {
                        name: "actions",
                        data: (value, item) => (
                          <Box className="d-flex  gap-3">
                            <Button
                              className="btn btn_bg_ter"
                              variant="contained"
                              color="info"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                setifthenProductEdit(item)
                                setIfthenPModal(true)
                              }}>
                              Edit
                            </Button>

                            <Button
                              className="btn btn_bg_ter"
                              variant="contained"
                              color="error"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                removeIfThenProduct(item._id)
                              }}>
                              Delete
                            </Button>
                          </Box>
                        )
                      }
                    ]}
                  />
                ) : (
                  <div className="w-100 d-flex justify-content-center slign-items-center">
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Box>
            <Box className="mt-5">
              <Typography variant="h6">Energetic Imbalance If-Then Products</Typography>
              <Grid container>
                {/* <Grid item xs={6} className="p-3 d-flex justify-content-end">
                  <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search " }}
                      onKeyUp={e => e.key === "Enter" && filterIfThen(search)}
                    />
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                      <SearchIcon onClick={() => filterIfThen(search)} />
                    </IconButton>
                  </Paper>
                </Grid> */}
                <Grid item xs={12} className="p-3 d-flex justify-content-end">
                  <Button
                    className="btn btn_bg_ter "
                    variant="contained"
                    style={{paddingTop:12,paddingBottom:12}}
                    onClick={() => {
                      setEngRefPModal(true)
                    }}>
                    Add Product
                  </Button>
                </Grid>
                {!load ? (
                  <TableMui
                    styleTableContainer={{ height: "70vh" }}
                    styleTableTh={{
                      color: "white",
                      backgroundColor: "#3E4755",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                    th={{
                      energetic_imbalance: "Energetic Item",
                      "product.name": "Product",
                      product_recommended: "Product Recomented",
                      pet_type: "Pet Type",
                      actions: "Action"
                    }}
                    td={engRefProductData}
                    customFields={[
                      {
                        name: "product_recommended",
                        data: (value, item) =>
                          value ? (
                            <Chip
                              label="Recommended"
                              style={{ backgroundColor: "green", color: "white" }}
                            />
                          ) : (
                            <Chip
                              label="Not-Recommended"
                              style={{ backgroundColor: "red", color: "white" }}
                            />
                          )
                      },
                      {
                        name: "pet_type",
                        data: (value, item) => value?.toUpperCase() || "All"
                      },
                      {
                        name: "actions",
                        data: (value, item) => (
                          <Box className="d-flex  gap-3">
                            <Button
                              className="btn btn_bg_ter"
                              variant="contained"
                              color="info"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                setEngRefProductEdit(item)
                                setEngRefPModal(true)
                              }}>
                              Edit
                            </Button>

                            <Button
                              className="btn btn_bg_ter"
                              variant="contained"
                              color="error"
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                removeEnerRefProduct(item._id)
                              }}>
                              Delete
                            </Button>
                          </Box>
                        )
                      }
                    ]}
                  />
                ) : (
                  <div className="w-100 d-flex justify-content-center slign-items-center">
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Box>
          </Grid>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={3}>
          <Products />
          <Grid container>
            <Grid item xs={6} className="p-3 d-flex justify-content-end">
              <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search " }}
                  onKeyUp={e => e.key === "Enter" && filterIfThen(search)}
                />
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                  <SearchIcon onClick={() => filterIfThen(search)} />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={6} className="p-3 d-flex justify-content-end">
              <Button
                className="btn btn_bg_ter"
                variant="contained"
                onClick={() => {
                  // setItemDataEdit(false)
                  setProduct(true)
                }}>
                Add Item
              </Button>
            </Grid>
            {!load ? (
              <TableMui
                styleTableContainer={{ height: "70vh" }}
                styleTableTh={{
                  color: "white",
                  backgroundColor: "#3E4755",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                }}
                th={{
                  name: "Name",
                  link: "Link",
                  description: "Description",
                  image: "Image",
                  actions: "Action"
                }}
                td={productData}
                customFields={[
                  {
                    name: "actions",
                    data: (value, item) => (
                      <Box className="d-flex gap-3">
                        {/* <Button
          className='btn btn_bg_ter'
          variant="contained"
          color="info"
          style={{ textDecoration: "none" }}
          onClick={() => {
            // setItemDataEdit(item);
            // setItem(true);
            setProductEdit(item)
            setProduct(true)
          }}
        >
          Edit
        </Button> */}
                        <Button
                          className="btn btn_bg_ter"
                          variant="contained"
                          color="error"
                          style={{ textDecoration: "none" }}
                          onClick={() => removeProduct(item._id)}>
                          Delete
                        </Button>
                      </Box>
                    )
                  },
                  {
                    name: "link",
                    data: (value, item) => item.link.substring(0, 20) + "..."
                  },
                  {
                    name: "image",
                    data: (value, item) => (
                      <img src={item.image} alt="Product Image" width="50" height="50" />
                    )
                  },
                  {
                    name: "description",
                    data: (value, item) => item.description.substring(0, 20) + "..."
                  }
                ]}
              />
            ) : (
              <div className="w-100 d-flex justify-content-center slign-items-center">
                <CircularProgress />
              </div>
            )}
          </Grid>
        </CustomTabPanel>

        {category && (
          <Category
            categoryEdit={categoryEdit}
            fetchData={fetchData}
            show={category}
            onHide={() => setCategory(false)}
          />
        )}
        {item && (
          <Item
            fetchItemData={fetchItemData}
            itemDataEdit={itemDataEdit}
            categoryData={categoryData}
            show={item}
            onHide={() => setItem(false)}
          />
        )}

        {ifthen && (
          <IfThen
            fetchifthenData={fetchifthenData}
            ifthenEdit={ifthenEdit}
            categoryData={categoryData}
            itemData={itemData}
            show={ifthen}
            onHide={() => {
              setIfthen(false)
              setifthenEdit(null)
            }}
          />
        )}

        {ifthenProductModal && (
          <IfThenProduct
            fetchifthenData={fetchifthenProductData}
            ifthenEdit={ifthenProductEdit}
            productData={productData}
            itemData={itemData}
            show={ifthenProductModal}
            onHide={() => {
              setIfthenPModal(false)
              setifthenProductEdit(null)
            }}
          />
        )}
        {engRefProductModal && (
          <EngRefProductModalComp
            fetchifthenData={fetchEnerRefProductData}
            ifthenEdit={engRefProductEdit}
            productData={productData}
            show={engRefProductModal}
            onHide={() => {
              setEngRefPModal(false)
              setEngRefProductEdit(null)
            }}
          />
        )}

        {Products && product && (
          <Products
            fetchproduct={fetchproduct}
            productData={productData}
            productEdit={productEdit}
            show={product}
            onHide={() => {
              setProduct(false)
              setProductEdit(null)
            }}
          />
        )}
      </Box>
    </div>
  )
}

function Category(props) {
  // console.log(props.categoryEdit,"nml");
  const [categoryName, setCategoryName] = useState(
    props.categoryEdit ? props.categoryEdit.name : ""
  )
  const dispatch = useDispatch()
  const handleSubmit = async e => {
    e.preventDefault()
    let result
    if (props.categoryEdit) {
      result = await apiClient.put(`/report/category/${props.categoryEdit._id}`, {
        name: categoryName
      })
    } else {
      result = await apiClient.post("/report/category/", { name: categoryName })
    }
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    props.onHide()
    props.fetchData()
  }
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Grid container className=" d-flex justify-content-center align-items-center">
            <Grid item xs={8} spacing={3} className="d-flex gap-4">
              <TextField
                id="outlined-basic"
                value={categoryName}
                label="Category"
                fullWidth
                variant="outlined"
                onChange={e => setCategoryName(e.target.value)}
              />
              <Button type="submit" className="btn btn_bg_ter" variant="contained">
                {props.categoryEdit ? "Edit" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  )
}

function Item(props) {
  let initialState = {
    category: props.itemDataEdit ? props.itemDataEdit.category._id : "",
    name: props.itemDataEdit ? props.itemDataEdit.name : ""
  }
  const [itemAdd, setItemAdd] = useState(initialState)
  const dispatch = useDispatch()
  const handleChange = (key, value) => {
    setItemAdd({ ...itemAdd, [key]: value })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    let result
    if (props.itemDataEdit) {
      result = await apiClient.put(`/report/item/${props.itemDataEdit._id}`, itemAdd)
    } else {
      result = await apiClient.post("/report/item/", itemAdd)
    }
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    props.fetchItemData()
    props.onHide()
    // console.log(itemAdd);
  }
  // console.log(props.itemDataEdit,"hhhhhhh");
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} spacing={3} className="d-flex ">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  selected={itemAdd.category}
                  value={itemAdd.category}
                  label="Category"
                  onChange={e => handleChange("category", e.target.value)}>
                  {props.categoryData.map(item => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} spacing={3} className="d-flex ">
              <TextField
                id="outlined-basic"
                label="Item"
                value={itemAdd.name}
                onChange={e => handleChange("name", e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <Button type="submit" className="btn btn_bg_ter" variant="contained">
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  )
}

function IfThen(props) {
  // console.log(props.ifthenEdit, "nml");
  let initialState = {
    // category:props.itemDataEdit?props.itemDataEdit.category._id:"",
    // name:props.itemDataEdit?props.itemDataEdit.name:"",
    itemId: "",
    items: []
  }
  const [IfthenitemAdd, setIfthenItemAdd] = useState(props?.ifthenEdit?.items || [])
  const [IfthenitemAdd2, setIfthenItemAdd2] = useState(props?.ifthenEdit?.itemId?._id || "")

  const handleChange2 = e => {
    setIfthenItemAdd2(e.target.value)
  }
  const handleChange = (key, value) => {
    setIfthenItemAdd(value)
  }

  const [ifthenname, setIfthenname] = useState(props.categoryEdit ? props.categoryEdit.name : "")
  const dispatch = useDispatch()

  const handleSubmit = async e => {
    e.preventDefault()
    // console.log(IfthenitemAdd.map(item=>item._id),"lkio");
    // console.log(IfthenitemAdd2,"lkio2");
    let result

    if (props.ifthenEdit) {
      result = await apiClient.put(`/if-then/${props.ifthenEdit._id}`, {
        itemId: IfthenitemAdd2,
        items: IfthenitemAdd.map(item => item._id)
      })
    } else {
      result = await apiClient.post("/if-then/", {
        itemId: IfthenitemAdd2,
        items: IfthenitemAdd.map(item => item._id)
      })
    }

    if (!result.ok) {
      dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    }

    props.onHide()
    props.fetchifthenData()
  }

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">If-Then</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="d-flex justify-content-center align-items-center">
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">If-Items</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={IfthenitemAdd2}
                  onChange={handleChange2}
                  label="Category">
                  {props.itemData.map(item => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={props.itemData}
                disableCloseOnSelect
                onChange={handleChange}
                value={IfthenitemAdd}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )
                }}
                renderInput={params => (
                  <TextField {...params} label="Then-Items" placeholder="items" />
                )}
              />
            </Grid>
            <Grid item xs={12} mt={5} className="d-flex justify-content-center">
              <Button type="submit" className="btn btn_bg_ter" variant="contained">
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  )
}

function IfThenProduct({ fetchifthenData, ifthenEdit, productData, itemData, show, onHide }) {
  // console.log(props.ifthenEdit, "nml");
  let initialState = {
    itemId: ifthenEdit?.itemId._id || "",
    item_senstivity: ifthenEdit?.item_senstivity || "HG",
    product: ifthenEdit?.product._id || "",
    product_recommended: ifthenEdit?.product_recommended || false,
    pet_type: ifthenEdit?.pet_type || ""
  }
  const [formData, setFormData] = useState(initialState)

  const handleChange = (key, value) => {
    setFormData(prev => {
      return { ...prev, [key]: value }
    })
  }

  const dispatch = useDispatch()

  const handleSubmit = async e => {
    e.preventDefault()
    const endpoint = ifthenEdit ? `/if-then/products/${ifthenEdit._id}` : "/if-then/products/"
    const method = ifthenEdit ? "put" : "post"

    const result = await apiClient[method](endpoint, formData)

    if (!result.ok) {
      dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    }

    onHide()
    fetchifthenData()
  }
  const petTypesArr = [
    { name: "All", value: "" },
    { name: "Dog", value: "dog" },
    { name: "Cat", value: "cat" },
    { name: "Equine", value: "equine" }
  ]
  const itemSenstivityArr = [
    { name: "High", value: "HG" },
    { name: "Medium", value: "MD" }
  ]
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">If-Then</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="d-flex justify-content-center align-items-center">
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Intolerance Item</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.itemId}
                  onChange={e => handleChange("itemId", e.target.value)}
                  label="Intolerance Item">
                  {itemData.map(item => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Product</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.product}
                  onChange={e => handleChange("product", e.target.value)}
                  label="Product">
                  {productData.map(item => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink id="demo-simple-select-label">
                  For Pet Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  displayEmpty
                  value={formData.pet_type}
                  onChange={e => handleChange("pet_type", e.target.value)}
                  label="For Pet Type"
                  input={<OutlinedInput notched label="For Pet Type" />}>
                  {petTypesArr.map(item => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Intolerance Sensitivity</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.item_senstivity}
                  onChange={e => handleChange("item_senstivity", e.target.value)}
                  label="Intolerance Sensitivity">
                  {itemSenstivityArr.map(item => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Product Recommendation
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formData.product_recommended}
                  onChange={e => handleChange("product_recommended", e.target.value)}>
                  <FormControlLabel value={true} control={<Radio />} label="Recommended" />
                  <FormControlLabel value={false} control={<Radio />} label="Not Recommmended" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={5} className="d-flex justify-content-center">
              <Button type="submit" className="btn btn_bg_ter" variant="contained">
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  )
}
function EngRefProductModalComp({ fetchifthenData, ifthenEdit, productData, show, onHide }) {
  const energeticData = [
    "Bacteria",
    "Chlorine",
    "Fungus",
    "Chloride",
    "Pesticide",
    "Inflammation",
    "Mold",
    "Pain",
    "Parasites",
    "Virus",
    "Yeast",
    "Candida",
    "Herbicides",
    "Emotions",
    "Vaccinosis",
    "Thyroid"
  ]

  // console.log(props.ifthenEdit, "nml");
  let initialState = {
    energetic_imbalance: ifthenEdit?.energetic_imbalance || "",
    product: ifthenEdit?.product._id || "",
    product_recommended: ifthenEdit?.product_recommended || false,
    pet_type: ifthenEdit?.pet_type || ""
  }
  const [formData, setFormData] = useState(initialState)

  const handleChange = (key, value) => {
    setFormData(prev => {
      return { ...prev, [key]: value }
    })
  }

  const dispatch = useDispatch()

  const handleSubmit = async e => {
    e.preventDefault()
    const endpoint = ifthenEdit ? `/if-then/energetic/${ifthenEdit._id}` : "/if-then/energetic/"
    const method = ifthenEdit ? "put" : "post"

    const result = await apiClient[method](endpoint, formData)

    if (!result.ok) {
      dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message
        })
      )
    }

    onHide()
    fetchifthenData()
  }
  const petTypesArr = [
    { name: "All", value: "" },
    { name: "Dog", value: "dog" },
    { name: "Cat", value: "cat" },
    { name: "Equine", value: "equine" }
  ]
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">If-Then</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="d-flex justify-content-center align-items-center">
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Intolerance Item</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.energetic_imbalance}
                  onChange={e => handleChange("energetic_imbalance", e.target.value)}
                  label="Intolerance Item">
                  {energeticData.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Product</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.product}
                  onChange={e => handleChange("product", e.target.value)}
                  label="Product">
                  {productData.map(item => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink id="demo-simple-select-label">
                  For Pet Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  displayEmpty
                  value={formData.pet_type}
                  onChange={e => handleChange("pet_type", e.target.value)}
                  label="For Pet Type"
                  input={<OutlinedInput notched label="For Pet Type" />}>
                  {petTypesArr.map(item => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Product Recommendation
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formData.product_recommended}
                  onChange={e => handleChange("product_recommended", e.target.value)}>
                  <FormControlLabel value={true} control={<Radio />} label="Recommended" />
                  <FormControlLabel value={false} control={<Radio />} label="Not Recommmended" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={5} className="d-flex justify-content-center">
              <Button type="submit" className="btn btn_bg_ter" variant="contained">
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  )
}
function Products(props) {
  // console.log(props.productEdit," product edit data")
  let initialState = {
    name: props.productEdit?.name || "",
    link: props.productEdit?.link || "",
    description: props.productEdit?.description || "",
    image: props.productEdit?.image || ""
  }

  const [productAdd, setproductAdd] = useState(initialState)
  const dispatch = useDispatch()
  const handleChange = (key, value) => {
    setproductAdd({ ...productAdd, [key]: value })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("name", productAdd.name)
    formData.append("link", productAdd.link)
    formData.append("description", productAdd.description)
    formData.append("image", productAdd.image)

    let result

    if (props.productEdit) {
      result = await apiClient.patch(`/report/product/${props.productEdit._id}`, formData)
    } else {
      result = await apiClient.post("/report/product", formData)
    }

    if (!result.ok) {
      toast.error("Error adding product")
    } else {
      toast.success("Product added successfully")
    }
    props.fetchproduct()

    // console.log(result, "product result")
    props.onHide()
    // console.log(productAdd);
  }
  // console.log(props.itemDataEdit,"hhhhhhh");
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} spacing={3} className="d-flex ">
              <TextField
                id="outlined-basic"
                label="GPH Product"
                value={productAdd?.name}
                onChange={e => handleChange("name", e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} spacing={3} className="d-flex ">
              <TextField
                id="outlined-basic"
                label="Link to Product page"
                value={productAdd.link}
                onChange={e => handleChange("link", e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} spacing={3} className="d-flex ">
              <TextField
                id="outlined-basic"
                multiline
                label="Discription"
                value={productAdd.description}
                onChange={e => handleChange("description", e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} spacing={3} className="d-flex ">
              <input
                onChange={e => handleChange("image", e.target.files[0])}
                type="file"
                name=""
                id=""
              />
              {/* <TextField id="outlined-basic" label="Discription" value={productAdd.description} onChange={(e)=>handleChange("description",e.target.value)} fullWidth variant="outlined"  /> */}
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <Button type="submit" className="btn btn_bg_ter" variant="contained">
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  )
}
