import Modal from "react-bootstrap/Modal"
import HelpIcon from "@mui/icons-material/Help"
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material"
import { useState } from "react"
import apiClient from "../api/apiClient"
import { useDispatch } from "react-redux"
import { showModal } from "../redux/modalSlice"
import SelectOption from "./mui/SelectOption"
import moment from "moment"
function CenterModal(props) {
  const dispatch = useDispatch()
  const [qrr, setQrr] = useState()
  const [barcodeType, setType] = useState("")
  const [exportType, setExportType] = useState("csv")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setQrr("")
    const data = {
      export_type: exportType,
      quantity: qrr,
      barcode_type: barcodeType
    }
    //console.log(generateObjectArray());
    const result = await apiClient.post(
      "/qr/generate-and-export",
      data,

      { responseType: "blob" }
    )
    if (!result.ok) {
      setLoading(false)
      return dispatch(
        showModal({
          error: true,
          title: "Barcode",
          message: result.data.message
        })
      )
    }

    const blob = await result.data
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    a.download = `barcodes-${moment().format("DD-MM-YY")}.${exportType}`
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    setLoading(false)
    props.fetchData()
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog-centered" // Add this class
    >
      <Modal.Header className=" " closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
          <HelpIcon style={{ fontSize: "50px", color: "#737983" }} />
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <h4 className="text-center">Generate Barcodes</h4>

          {/* <p className="text-center">Please give me the number of barcodes and barcode type you want to generate and export type.</p> */}

          <Grid container style={{ justifyContent: "center" }} spacing={2}>
            <Grid item xs={8} className="align-items-center">
              <FormControl className="d-flex justify-content-center align-items-center">
                <FormLabel id="radio-label" className="text-center ">
                  Export Format
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="radio-label"
                  name="row-radio-buttons-group"
                  value={exportType}
                  onChange={e => setExportType(e.target.value)}>
                  <FormControlLabel value="csv" control={<Radio size="small" />} label="CSV" />
                  <FormControlLabel value="pdf" control={<Radio size="small" />} label="PDF" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                type="number"
                id="outlined-basic"
                value={qrr}
                onChange={e => setQrr(e.target.value)}
                label="No. of Barcodes"
                variant="outlined"
                size="small"
                InputProps={{
                  inputProps: exportType === "pdf" ? { min: 1, max: 100 } : { min: 1 }
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <SelectOption
                required
                style={{ width: "100%" }}
                data={[
                  { value: "normal", label: "Normal" },
                  { value: "pp", label: "PP Barcodes" },
                  { value: "exp", label: "EXP Barcodes" }
                ]}
                id="outlined-basic"
                size="small"
                value={barcodeType}
                onChange={e => {
                  setType(e.target.value)
                  // console.log(e.target.value);
                }}
                label="Barcode Type"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            fullWidth
            sx={{ borderRadius: 20 }}
            type="submit"
            variant="contained"
            className="btn_bg_secondery px-5"
            disabled={loading}
            
            >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Generate"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
export default CenterModal
