import * as yup from "yup"
import { calculateOperationTime } from "./helpers"

const runValidations = (schema, data) => {
  try {
    schema.validateSync(data, { abortEarly: false })
    return { success: true }
  } catch (err) {
    const errorsObj = {}
    err.inner.forEach(validationError => {
      if (!errorsObj[validationError.path]) {
        errorsObj[validationError.path] = []
      }
      errorsObj[validationError.path].push(validationError.message)
    })

    return { success: false, validationErrors: errorsObj }
  }
}
const removeEmptyValues = obj => {
  const newObj = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (typeof value === "object") {
        // Recursively remove empty values for nested objects or arrays
        const nestedValue = removeEmptyValues(value)

        if (Object.keys(nestedValue).length > 0) {
          newObj[key] = nestedValue
        }
      } else if (value !== "" && value !== null && value !== undefined) {
        // Include non-empty values for non-object properties
        newObj[key] = value
      }
    }
  })

  return newObj
}

export { runValidations }
