import { create } from "apisauce"
import { toast } from "react-toastify"
import { store } from "../redux/store"
import { logout } from "../redux/counterSlice"
const baseURL = process.env.REACT_APP_BASE_URL
const apiClient = create({
  baseURL: baseURL
})
const authToken = localStorage.getItem("token")
if (authToken) apiClient.setHeader("authorization", `Bearer ${authToken}`)

function setAuthToken(token) {
  apiClient.setHeader("authorization", `Bearer ${token}`)
}

apiClient.addResponseTransform(response => {
  // Handle response errors
  if (response.status === 401) {
    if (store?.getState()?.auth?.token) {
      toast.error("Session Expired")
      store.dispatch(logout())
    }
  } else if (response.status === 403) {
    // toast.error('Restricted Route!!');
  }
})

export { setAuthToken }
export default apiClient
