import React from "react"
import { Search } from "@mui/icons-material"
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography
} from "@mui/material"
import { Link } from "react-router-dom"
import SampleTabs from "../../components/SampleTabs"
import ClientSampleTabs from "../../components/ClientSampleTabs"
import { Helmet } from "react-helmet"
function ClientSamples() {
  return (
    <div>
      <Helmet>
        <title>Samples</title>
      </Helmet>
      <Container className="" maxWidth="lg">
        <Grid container className="d-flex justify-content-center " spacing={2}>
          <Grid item md={10} sx={{ backgroundColor: "" }} xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <h3 className="mb-0">Samples:</h3>
              {/* <div className='d-flex justify-content-between align-items-center px-4 col-md-6' style={{ backgroundColor: "#f1f1f5", borderRadius: "50px" }}>
  <InputBase
    className=''
    sx={{
      ml: 1,
      flex: 1,
      px: 1,
    }}
    placeholder="Search..."
    type='search'
  />
  <Box sx={{ width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', p:1  }}>
    <IconButton
      type="button"
      sx={{
        p: '5px',
        backgroundColor: '#3E4755',
        color: 'white',
        '&:hover': {
          backgroundColor: '#3E4755',
          color: 'white',
        },
      }}
      aria-label="search"
    >
      <Search style={{ fontSize: 25 }} />
    </IconButton>
  </Box>
</div> */}
            </div>
          </Grid>
        </Grid>
        <Grid>
          <ClientSampleTabs />
        </Grid>
      </Container>
    </div>
  )
}
export default ClientSamples
