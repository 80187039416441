import React from "react"

const NotFound = () => {
  return (
    <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  )
}

export default NotFound
