import { Search } from "@mui/icons-material"
import { Box, Container, Grid, IconButton, InputBase, Paper } from "@mui/material"
import { Helmet } from "react-helmet"
import React from "react"
import { Linechart } from "../components/Linechart"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import { Icon } from "@mui/material"
import ClientDashboardAnalytics from "../components/ClientDashboardAnalytics"
import { useEffect, useState } from "react"
import axios from "axios"
import apiClient from "../api/apiClient"
import Card from "@mui/material/Card"
import { Chart } from "react-google-charts"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Bar } from "react-chartjs-2"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top"
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart"
    }
  },
  scales: {
    x: {
      grid: {
        offset: true
      }
    }
  }
}

const labels = [["Clients"], ["Samples"], ["Technicians"]]

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

function Dashboard() {
  const [sample, setSample] = useState(0)
  const [tech, setTech] = useState(0)
  const [client, setClient] = useState(0)

  const fetchData = async () => {
    try {
      const [apiSample, apiTech, apicl] = await Promise.all([
        apiClient.get("/sample/"),
        apiClient.get("/auth/all-users-t"),
        apiClient.get("/client/")
      ])

      if (apiSample.ok && apiTech.ok && apicl.ok) {
        const sample = apiSample.data.count
        //console.log(sample, "sample");
        const tech = apiTech.data.count
        //console.log(tech, "tn");
        const client = apicl.data.count

        //console.log(client, "client");
        // Update the state with the retrieved data
        setSample(sample)
        setTech(tech)
        setClient(client)
      }
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // const data = [['Technician', 'Value'], ...technicianNames.map((name) => [name, 0])];
  // const data = [['Day', 'Technicians'], ...Array.from({ length: 31 }, (_, i) => [i + 1, tech])];
  // const data = [
  //   ["Day", "Technicians"],
  //   [1, 1],
  //   [2, 5],
  //   [3, 12],
  //   [4, 43],
  //   [5, 2],
  //   [6, 4],
  //   [7, 0],
  //   [8, 0],
  //   [9, 0],
  //   [10, 0],
  //   [11, 0],
  //   [12, 0],
  //   [13, 0],
  //   [14, 0],
  //   [15, 0],
  //   [16, 0],
  //   [17, 10],
  //   [18, 0],
  //   [19, 0],
  //   [20, 0],
  //   [21, 0],
  //   [22, 0],
  //   [23, 0],
  //   [24, 0],
  //   [25, 0],
  //   [26, 20],
  //   [27, 0],
  //   [28, 0],
  //   [29, 0],
  //   [30, 0],
  //   [31, 0],
  // ];

  // //console.log(data);
  // const data = [
  //   ['name', ''],
  //   ['Name', 0],
  //   ['Name', 10],
  //   ['Name', 23],
  //   ['Name', 17],
  //   ['Name', 18],
  //   ['Name', 9],
  //   ['Name', 11],
  //   ['Name', 27],
  // ];

  // const options = {
  //   bars: "vertical",
  //   bar: { groupWidth: "10%" }, // Adjust the value to control the bar width
  //   colors: ["#5733FF"], // Replace with your desired colors for each bar
  //   // Other chart options (if any)
  // };

  // const data = {
  //   datasets: [
  //     {
  //       label: "Clients",
  //       data: [5], // Replace these values with your data for Section 1
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //     },
  //     {
  //       label: "Samples",
  //       data: [3], // Replace these values with your data for Section 2
  //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  //     },
  //     {
  //       label: "Technicians",
  //       data: [2], // Replace these values with your data for Section 3
  //       backgroundColor: "rgba(75, 192, 192, 0.5)",
  //     },
  //   ],
  // };
  const data = {
    labels: ["Clients", "Samples", "Technicians"],
    datasets: [
      {
        barThickness: 50,
        maxBarThickness: 50,
        label: "Data",
        data: [client, sample, tech],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(255, 159, 64, 0.2)", "#E0ECFF"],
        borderColor: ["rgb(255, 99, 132)", "rgb(255, 159, 64)", "rgb(255, 205, 86)"],
        borderWidth: 1
      }
    ]
  }

  //console.log(data);
  //console.log(client,sample,tech,"asd");
  // const data = [
  //   ["", "Data"],
  //   ['Clients', client],
  //   ['Samples', sample],
  //   ['Technicians', tech],
  // ];
  // const data = [
  //   ['Clients', client],
  //   ['Samples', sample],
  //   ['Technicians', tech],
  // ];

  return (
    <div className="">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Container className="" maxWidth="lg">
        <Grid container className="d-flex justify-content-center " spacing={2}>
          <Grid item md={10} sx={{ backgroundColor: "" }} xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <h3 className="mb-0">Dashboard:</h3>
            </div>
          </Grid>
        </Grid>
        <Grid className="d-flex justify-content-center " container>
          <Grid
            className=" "
            md={10}
            xs={12}
            spacing={2}
            component={Paper}
            elevation={5}
            sx={{ marginTop: 5, padding: 5, borderRadius: 10 }}
          >
            <Grid container spacing={0} className="">
              <Grid className=" " item md={4} xs={12}>
                <Card className="shadow" sx={{ minWidth: 270, margin: "10px" }}>
                  <CardContent className="d-flex align-items-center gap-2 ">
                    <Icon
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#E0ECFF",
                        color: "#4791FF",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "50px"
                      }}
                    >
                      <ContentPasteIcon />
                    </Icon>
                    <Box>
                      <Typography variant="p" component="div" sx={{ fontSize: "12px" }}>
                        Technicians Added
                      </Typography>
                      <Typography variant="h6" component="div">
                        {tech}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid className=" " item md={4} xs={12}>
                <Card className="shadow" sx={{ minWidth: 270, margin: "10px" }}>
                  <CardContent className="d-flex align-items-center gap-2 ">
                    <Icon
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#FFF9E1",
                        color: "#FFD950",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "50px"
                      }}
                    >
                      <ContentPasteIcon />
                    </Icon>
                    <Box>
                      <Typography variant="p" component="div" sx={{ fontSize: "12px" }}>
                        Samples Received
                      </Typography>
                      <Typography variant="h6" component="div">
                        {sample}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid className=" " item md={4} xs={12}>
                <Card className="shadow" sx={{ minWidth: 270, margin: "10px" }}>
                  <CardContent className="d-flex align-items-center gap-2 ">
                    <Icon
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#FFDAE5",
                        color: "#FF2366",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "50px"
                      }}
                    >
                      <ContentPasteIcon />
                    </Icon>
                    <Box>
                      <Typography variant="p" component="div" sx={{ fontSize: "12px" }}>
                        Clients Added
                      </Typography>
                      <Typography variant="h6" component="div">
                        {client}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid component={Paper} elevation={5} sx={{ marginTop: 4 }}>
              <div className="p-3">
                <p className="mb-0 fs_14px  ">Total Technicians Added</p>
                <h5>{tech}</h5>
              </div>
              <div className="d-flex justify-content-center">
                <Bar options={options} data={data} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default Dashboard
