import React, { useRef } from "react"
import { useBarcode } from "next-barcode"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { Divider, IconButton, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import html2canvas from "html2canvas" // Import html2canvas library
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
function BarcodeListArchive({ value, index, ShowiIcon, url }) {
  const divRef = useRef(null)

  const handleDownload = async () => {
    // Convert the SVG to an image using html2canvas
    const canvas = await html2canvas(divRef.current, {
      scale: 2 // You can adjust the scale for better resolution
    })
    //console.log(canvas);

    // Convert the canvas to a data URL (PNG format)
    const dataUrl = canvas.toDataURL("image/png")

    // Create a temporary anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = dataUrl
    anchorElement.download = "barcode_image.png" // Specify the filename for the downloaded image

    // Programmatically click the anchor element to trigger the download
    anchorElement.click()
  }

  return (
    <div className="">
      <Barcode
        url={url}
        ShowiIcon={ShowiIcon}
        key={index}
        activated={value?.activated}
        value={value.key}
        handleDownload={handleDownload}
        divRef={divRef} // Pass the ref to the Barcode component
      />
    </div>
  )
}

function Barcode({ value, ShowiIcon, url, divRef, handleDownload, activated }) {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      background: "#F5F5F5",
      width: 2
    }
  })

  return (
    <div
      className="p-2"
      style={{
        backgroundColor: "#F1F1F5",
        maxWidth: "240px",
        borderRadius: "10px",
        position: "relative"
      }}
    >
      {activated && (
        <CheckCircleIcon style={{ color: "green", position: "absolute", top: "5", left: "10" }} />
      )}
      <div
        ref={divRef}
        className="p-2"
        style={{ backgroundColor: "#F1F1F5", maxWidth: "240px", borderRadius: "10px" }}
      >
        <div className="d-flex">
          <svg
            style={{ width: "100%", maxWidth: "100px" }} // Increase the width as needed
            ref={inputRef}
          />
        </div>
        <Divider sx={{ margin: 0 }} />
        <Typography className="text-center" variant="body2" style={{ maxWidth: "300px" }}>
          <span
            className="text-wrap"
            target="_blank"
            style={{
              display: "block",
              wordWrap: "break-word",
              textDecoration: "none",
              color: "black"
            }}
          >
            {url}
          </span>
        </Typography>
      </div>
    </div>
  )
}

function BarcodeOriginal({ value, url }) {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      background: "#F5F5F5",
      width: 2,
      height: 50
    }
  })

  return (
    <div
      style={{
        backgroundColor: "#F3EFEF",
        width: "57mm",
        height: "32mm"
      }}
    >
      <div className="d-flex justify-content-center">
        <svg style={{ width: "100%" }} height="22mm" ref={inputRef} />
      </div>
      <div className="text-center" style={{ height: "10mm" }}>
        <span style={{ color: "black" }}>{url}</span>
      </div>
    </div>
  )
}

export { BarcodeOriginal }
export default BarcodeListArchive
