import React from "react"
import { Search } from "@mui/icons-material"
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography
} from "@mui/material"
import { Link } from "react-router-dom"
import SampleTabs from "../components/SampleTabs"
import { Helmet } from "react-helmet"
function Samples() {
  return (
    <div>
      <Helmet>
        <title>Samples</title>
      </Helmet>
      <Container className="" maxWidth="lg">
        <Grid container className="d-flex justify-content-center " spacing={2}>
          <Grid item md={10} sx={{ backgroundColor: "" }} xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <h3 className="mb-0">Samples:</h3>
            </div>
          </Grid>
        </Grid>

        <Grid>
          <SampleTabs />
        </Grid>
      </Container>
    </div>
  )
}
export default Samples
