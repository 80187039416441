import { Button, CircularProgress, Grid, useMediaQuery } from "@mui/material"
import { Box, createTheme } from "@mui/system"
import React, { useEffect, useRef } from "react"
import BackButton from "../../components/mui/BackButton"
import { Products } from "../../components/Products"
import Webnav from "../../components/Webnav"
import { useState } from "react"
import apiClient from "../../api/apiClient"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import html2pdf from "html2pdf.js"

import { useReactToPrint } from "react-to-print"
import { handleErrors } from "../../modules/handleErrors"
import { toast } from "react-toastify"
import downloadFile from "../../modules/downloadFile"
import { isGreenCategory } from "../technician/CreateReport"
import { copyRightYear } from "../../modules/helpers"
const GetReport = () => {
  const [gridData, setGridData] = useState()
  const [products, setProducts] = useState([])
  const [alldata, setAllData] = useState()
  const [newData, setNewData] = useState()
  const { barcode } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const downloadMode = searchParams.get("download")

  const customBreakpoints = {
    xs: 0, // Extra small devices (portrait phones)
    sm: 900, // Small devices (landscape phones)
    md: 960, // Medium devices (tablets)
    lg: 1280, // Large devices (laptops/desktops)
    xl: 1920, // Extra large devices (large laptops/desktops)
    xxl: 2400 // Extra-extra large devices
  }

  const theme = createTheme({
    breakpoints: {
      values: customBreakpoints
    }
  })
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md"))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"))

  //console.log(isMediumScreen, " isSmallScreen");

  const Immune = [
    {
      items: ["Bacteria", "Chlorine", "Chloride", "Herbicide", "Pesticide", "Inflammation", "Mold"]
    },
    {
      items: ["Pain", "Parasites", "Thyroid", "Vaccinosis", "Fungus"]
    }
  ]
  const navigate = useNavigate()
  const fetchData = async () => {
    const clientResult = await apiClient.get(`/client/by_barcode/${barcode}`)
    if (!clientResult.ok) {
      navigate(-1)
      return handleErrors(clientResult.data)
    }
    //console.log(clientResult, "clientresultttt");

    setNewData(clientResult.data.result)
    const result = await apiClient.get(`/report/${barcode}/`)
    if (!result.ok) {
      navigate(-1)
      return handleErrors(result.data)
    }
    let gridDataTemp = { ...result.data, products: result.data?.products?.filter(x => x?.product) }
    setGridData(gridDataTemp)

    const resultProduct = await apiClient.get("/report/product/?limit=10000")
    if (!result.ok) return
    let dataP = resultProduct.data.results
    if (dataP?.length > 0) {
      setProducts([
        ...gridDataTemp.products,
        ...dataP
          .filter(item => gridDataTemp.products.every(x => x.product._id !== item._id))
          .map(item => {
            return { product: item, status: "" }
          })
      ])
    } else {
      setProducts(gridDataTemp.products)
    }

    const resultRaw = await apiClient.get("/report/raw-data/")
    if (!resultRaw.ok) return
    let rawData = resultRaw?.data
    const targetCategory = "Toxic Foods"

    rawData = rawData.sort(
      (a, b) => (a.category === targetCategory) - (b.category === targetCategory)
    )

    if (!barcode.includes("pp"))
      rawData = resultRaw?.data.filter(
        x =>
          ![
            "GPH Products",
            "May Also Be Helpful:",
            "Cell Salts:",
            "Thyroid",
            "Virus",
            "Bacteria",
            "Inflammation",
            "Pain"
          ].includes(x.category)
      )

    const combinedWithSensitivity = combineWithSensitivity(rawData, result.data?.report_items)
    //console.log(combinedWithSensitivity);
    setAllData(combinedWithSensitivity)
  }

  useEffect(() => {
    fetchData()
  }, [])
  // console.log(gridData.products, "gridData ")
  //console.log(newData, "Client Data new");

  const handleGeneratePDF = () => {
    const element = document.getElementById("print_page")
    html2pdf().from(element).save()
  }

  const [downloadLoad, setDownloadLoad] = useState(false)
  const componentRef = useRef()
  // const htmlStringToPdf = async () => {
  //   const content = componentRef.current;

  //       const doc = new jsPDF();
  //       doc.html(content, {

  //         callback: function (doc) {

  //             doc.save('report.pdf');
  //         },
  //         html2canvas: { scale: 0.1 }, // change the scale to whatever number you need,
  //         x: 10,
  //   y: 15,
  //     });
  // };
  const handlePrint = async () => {
    setDownloadLoad(true)
    const result = await apiClient.get(`report/download/${barcode}`)
    if (!result.ok) {
      toast.error("Error")
      setDownloadLoad(false)
      return
    }
    downloadFile(result.data.result)
    setDownloadLoad(false)

    // const element = componentRef.current;
    // const options = {
    //   filename: "report.pdf", // Specify the filename for the downloaded PDF
    //   margin: [18, 0, 16, 0], // Set the margins for the PDF
    //   html2canvas: { scale: 1 }, // Adjust the scale for better resolution (optional)
    //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Set the PDF format and orientation
    // };
    // html2pdf().set(options).from(element).save();
    // setDownloadLoad(false);
  }
  function combineWithSensitivity(rawData, selectedData) {
    let combinedData = rawData.map(categoryItem => {
      let combinedCategory = {
        ...categoryItem,
        items: categoryItem.items.map(item => {
          let isMatched = selectedData.some(subItem =>
            subItem.items.some(reportItem => reportItem._id === item._id)
          )
          if (isMatched) {
            let matchedSubItem = selectedData.find(subItem =>
              subItem.items.some(reportItem => reportItem._id === item._id)
            )
            let matchedItem = matchedSubItem.items.find(reportItem => reportItem._id === item._id)
            return {
              ...item,
              senstivity: matchedItem.senstivity
            }
          }
          return item
        })
      }
      return combinedCategory
    })

    return combinedData
  }
  const getBackgroundColor = senstivity =>
    senstivity === "MD" ? "yellow" : senstivity === "HG" ? "red" : ""
  function findInGridDataByName(items, sign) {
    const asterisksCount = sign.length

    if (asterisksCount > 0) {
      const regexPattern = new RegExp(`^[^*]*\\*{${asterisksCount}}$`)
      const filteredData = items.filter(item => regexPattern.test(item.name))
      return filteredData?.length > 0
    } else {
      return false
    }
  }
  if (!newData || !gridData) return
  return (
    <div className="" style={{ backgroundColor: "white" }}>
      {/* <p>Window Width: {window.innerWidth}px</p>
      <p>Window Height: {window.innerHeight}px</p> */}
      {/* <Webnav/> */}
      {!downloadMode && (
        <Box className=" py-5 d-flex justify-content-center">
          <Grid className="d-flex align-items-center gap-2" container md="10" xs="12">
            <BackButton to={"/"} />
            <span className=" mb-0 fw-bold">Generating Report:</span>
          </Grid>
          <Button
            startIcon={downloadLoad ? <CircularProgress className="mx-2" size={20} /> : null}
            className="btn btn_bg_ter text-white "
            style={{ whiteSpace: "nowrap" }}
            onClick={handlePrint}
          >
            Download Report
          </Button>
        </Box>
      )}
      <div id="print_page" ref={componentRef}>
        <Box className="mx-auto" sx={{ height: "40px", width: { md: "83.4%" } }}></Box>
        <Box className=" d-flex justify-content-center ">
          <Grid
            className="p-3 text-white"
            container
            md="10"
            xs="12"
            style={{ backgroundColor: "#716879" }}
          >
            <div className="w-100">
              <div className="" style={{ width: "10rem" }}>
                <img width={"100%"} src={require("../../assets/logo-new.png")} alt="" />
              </div>
            </div>
            <div>
              <span className="fw-bold">The Glacier Peak Pet Intolerance Test </span>
              is meant to be used as a roadmap, to see where your pet is currently at with food, the
              environment, and energetically. <br />
              Use this as a guide to provide compatible foods, support the body to handle the world
              around them, and to supplement their bodies with remedies that can benefit their
              overall health & wellbeing.
            </div>
          </Grid>
        </Box>

        <Box className=" d-flex justify-content-center align-items-center">
          <Grid className=" bg-white" container md="10" xs="12">
            <img className="" width={"100%"} src={require("../../assets/01.png")} alt="" />
          </Grid>
        </Box>

        <Box className=" container d-flex justify-content-center text-white mb-3 ">
          {/* <Grid
            className="p-3 test"
            md="12"
            sx={{ backgroundColor: "#716879", fontSize: "2rem" }}
          >
            <p className="test w-100">
            Glacier Peak Pet Intolerance Test
            </p>
          </Grid> */}
          <div style={{ maxWidth: "100%", width: "100%" }}>
            <p
              className="text-white px-3 py-3"
              style={{ backgroundColor: "#716879", fontSize: "2rem" }}
            >
              Glacier Peak Pet Intolerance Test
            </p>
          </div>
        </Box>

        <div className=" d-flex justify-content-center w-100 ">
          <Grid className="bg-white p-2 " container md="8" xs="12">
            <Grid className="" item md="3" sm="12">
              <p className="fw-bold">
                Report Date:{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {" "}
                  {gridData?.report_created_date?.split("T")[0]}
                </span>{" "}
              </p>
              <p className="fw-bold">
                Pet Name:{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {" "}
                  {newData?.pet.pet_name}
                </span>{" "}
              </p>

              <p className="fw-bold">
                Pet Age:{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {" "}
                  {newData?.pet.age}
                </span>
              </p>
              <p className="fw-bold">
                Pet Sex:{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {" "}
                  {newData?.pet.gender}
                </span>
              </p>
            </Grid>
            <Grid
              style={{
                borderLeft: isSmallScreen ? "" : " 2px solid #716879",
                paddingLeft: isSmallScreen ? "" : "10px"
              }}
              item
              md="3"
              xs="12"
            >
              <p className="fw-bold">
                Species:{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {" "}
                  {newData?.pet.type}
                </span>
              </p>
              <p className="fw-bold white_space">
                Pet Parent name :{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {newData?.firstname + " " + newData?.lastname}
                </span>
              </p>
              <p className="fw-bold white_space">
                Pet Parent Address:{" "}
                <span className="ps-1" style={{ fontWeight: "normal" }}>
                  {" "}
                  {newData?.address}
                </span>
              </p>
            </Grid>
            <Grid item md="12" xs="12" className="">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <p
                    className="mb-0 fw-bold text-capitalize white_space text-wrap"
                    style={{ fontSize: "1.5rem" }}
                  >
                    helping your pets live their best lives
                  </p>
                  <p className="text-muted fw-bold">We Offer Solutions, Not Just Results</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* CAtegories Items */}
        <div
          className=" container px-2 d-flex justify-content-center "
          style={{ backgroundColor: "white" }}
        >
          <div className=" bg-white   w-100" container md={8} xs={12}>
            {alldata?.map(({ category, items }, index) => (
              <div className="w-100 py-0 break-avoid">
                {index === 0 && (
                  <div className=" mt-5 page-header">
                    <div className="mt-5">
                      <div style={{ maxWidth: "100%", width: "100%" }}>
                        <p
                          className="text-white px-3 py-3"
                          style={{
                            backgroundColor: "#716879",
                            fontSize: "2rem"
                          }}
                        >
                          {/* Glacier Peak Pet Intolerance Test */}
                          Item Sensitivity Rating
                        </p>
                      </div>
                      <div className="d-flex gap-3 align-items-center mb-1 px-2">
                        {/* <p className="fs_14px fw-bold mb-0">
                          Item Sensitivity Rating
                        </p> */}
                        <p className="mb-0">
                          <i className="bi bi-square-fill" style={{ color: "yellow" }}></i>
                          <span className="ps-2">Moderate</span>
                        </p>
                        <p className="mb-0">
                          <i className="bi bi-square-fill" style={{ color: "red" }}></i>
                          <span className="ps-2">High</span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className=" shadow p-1 py-3 text-center mt-5 text-white break-avoid"
                  style={{ backgroundColor: "#716879" }}
                >
                  <span className="break-avoid">{category}</span>
                </div>

                <div
                  className=" mt-4 row px-2 gx-0"
                  style={{ backgroundColor: "white", color: "#716879" }}
                >
                  {items.map((item, key) => (
                    <div key={key} className="col-3 d-flex break-avoid">
                      <div className="">
                        {item.senstivity === "MD" && (
                          <i
                            className="bi bi-square-fill"
                            style={{ color: "yellow", marginRight: "5px" }}
                          ></i>
                        )}
                        {item.senstivity === "HG" && (
                          <i
                            className="bi bi-square-fill"
                            style={{
                              color: isGreenCategory(category) ? "green" : "red",
                              marginRight: "5px"
                            }}
                          ></i>
                        )}
                        {!["HG", "MD"].includes(item.senstivity) && (
                          <i
                            className="bi bi-square-fill"
                            style={{ marginRight: "5px", opacity: "0%" }}
                          ></i>
                        )}
                      </div>
                      <div style={{ color: "#716879" }} className="">
                        <span>{item.name}</span>
                      </div>
                    </div>
                  ))}
                  {/* Render additional text based on the category */}
                  <div>
                    {category === "Fish" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: Oils</p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0">
                            **Includes: Clam, Crab, Lobster, Oysters, Shrimp
                          </p>
                        )}
                      </div>
                    )}
                    {category === "Dust" && (
                      <div className="mt-2">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">
                            *Flock is used to make a variety of objects, from carpets, clothing
                            apparel, and jewel boxes to paper goods (such as picture books, posters
                            and greeting cards) that have raised or rough threads. Flocking is a
                            fiber coating proce ss that can add dimension and texture to almost any
                            article.
                          </p>
                        )}
                        {findInGridDataByName(items, "***") && (
                          <p className="fs_10px mb-0">***Flock Dust - Dust from Carpet Fibers</p>
                        )}
                        {/* <p className="fs_10px mb-0">
              **Includes: Clam, Crab, Lobster, Oysters, Shrimp
            </p> */}
                      </div>
                    )}
                    {category === "Milks/Dairy" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: Water & Milk</p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0">**Includes: Pasteurized Products</p>
                        )}
                      </div>
                    )}
                    {category === "Nuts & Seeds" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: milks/oils/waters</p>
                        )}
                      </div>
                    )}
                    {category === "Legumes" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: butter/oils</p>
                        )}
                      </div>
                    )}

                    {category === "Spices & Herbs" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">*Includes: Oils, Butter, Water & Milk</p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0">**Includes: Oils & Butter</p>
                        )}
                      </div>
                    )}
                    {category === "Petro Chemicals" && (
                      <div className="mt-2   mb-2">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px ">
                            *Extracting edible Oils from Seeds & Vegetables; Also an additive in
                            gasoline, glue, varnish, ink
                          </p>
                        )}
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px mb-0 ">
                            **Additive in foods to absorb extra water and maintain moisture. Ex. Sea
                            soning blends, dried soups, salad dressing, baking mixes, powdered drink
                            mixes
                          </p>
                        )}
                      </div>
                    )}
                    {category === "Environmental Chemicals" && (
                      <div className="mt-2   mb-2">
                        {findInGridDataByName(items, "**") && (
                          <p className="fs_10px ">**Toluene used most common in paint thinners</p>
                        )}
                      </div>
                    )}

                    {category === "Pollens" && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "*") && (
                          <p className="fs_10px mb-0">
                            *Found in crude oil, paint thinners, paint brush cleaners, nail polish,
                            glues, stain removers
                          </p>
                        )}
                      </div>
                    )}
                    {category === "Misc." && (
                      <div className="mt-2  ">
                        {findInGridDataByName(items, "****") && (
                          <p className="fs_10px mb-0">
                            ****Electronics Cell Phones/Portable Phones,Computers,Home
                            Theaters,Microwaves,Stereo Systems,Televisions,etc.
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {index == alldata?.length - 1 && (
                  <>
                    <div
                      className="page-footer"
                      style={{
                        backgroundColor: "#F3E4D7",
                        width: "100%",
                        py: 1,
                        px: 3,
                        borderTop: "2px solid black"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <p className="fs_10px mb-0">www.gphtest.com</p>
                        <p className="fs_10px mb-0">
                          © {copyRightYear()} FABTECHSOL. All rights reserved.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-5 container py-2 d-flex justify-content-center">
          {gridData?.energetic_imbalance && (
            <EnergeticImbalance selectedText={gridData?.energetic_imbalance} />
          )}
        </div>

        {/* <div className=' py-2 d-flex justify-content-center' >
        <div className="py-5 w-100 shadow p-3 mb-1 bg-body rounded" container md={8} xs={12} >
        <p className="fs_14px p-2 text-center">
        <span>Disclaimer- </span>  The information provided by this scan is intended for educational and nutritional purposes only and is not intended to diagnose,
        treat, cure, or prevent any disease. It is not intended as conventional veterinary medical practice advice or to replace the advice or attention of
        certified veterinarians. You may wish to consult your holistic veterinarian before beginning or making changes in your pets’ diet, nutritional
        supplementation or exercise program. The statements on this scan have not been evaluated by the Food and Drug Administration nor are they
        approved by the Board of Veterinary Medicine. If you do not have a Holistic Veterinarian, you can find one in your area by visiting
        http://www.ahvma.org/
        </p>



        <p className="fs_14px p-2 text-center">
        All materials, contents, and process within this scan, process, and material thereafter are under service mark, trademark, and copyright laws. This
        scan is not to be duplicated under any authority other than Glacier Peak Holistics, a Pet Wellness, &copy; 2023 .
        </p>
        </div>
        </div> */}

        <Box className="" sx={{ overflow: "hidden" }}>
          {products?.length > 0 && <Products data={products} downloadMode={downloadMode} />}
        </Box>
      </div>
    </div>
  )
}

export default GetReport
const EnergeticImbalance = ({ selectedText }) => {
  console.log(selectedText, "selectedText")

  const data = {
    items: [
      "Bacteria",
      "Chlorine",
      "Fungus",
      "Chloride",
      "Pesticide",
      "Inflammation",
      "Mold",
      "Pain",
      "Parasites",
      "Virus",
      "Yeast",
      "Candida",
      "Herbicides",
      "Emotions",
      "Vaccinosis",
      "Thyroid"
    ]
  }
  return (
    <div className=" bg-white  py-2 w-100" container md={8} xs={12}>
      <div
        className=" shadow p-1  text-center text-white w-100"
        style={{ backgroundColor: "#3E4755" }}
      >
        ENERGETIC IMBALANCES
      </div>
      <div className="w-100 shadow p-3 mb-5 bg-body rounded">
        <p className="fw-bold fs_14px text-center">
          Highlighted areas reference imbalances that may require help to eliminate.
        </p>
        <div
          className=" d-flex justify-content-center "
          // style={{paddingBottom:selectedText.includes('Immune System') ?50:0}}
        >
          <Grid
            // className={` ${
            //       selectedText.includes("Immune System") ? "energetic-highlight" : ""
            //     }`}
            container
            md={6}
            xs={12}
          >
            <p
              className={`clickable-wrapper text-center w-100 mb-0 ${selectedText.includes("Immune System") ? "selected" : "text-white"}`}
              style={{
                backgroundColor: "#3E4755"
              }}
            >
              Immune System
            </p>

            {/* {selectedText?.map((item, index) => ( */}
            {data.items.map((item, index) => (
              <Grid sm={6} xs={12}>
                <div
                  // onClick={(event) => handleTextClick(item, 'red')}
                  className={` ${selectedText.includes(item) ? "selected" : "text-white"}`}
                >
                  <p
                    className={`text-center mb-0 `}
                    // style={{
                    //   backgroundColor: index % 4 >= 2 ? "#828383" : "#D1A68F",
                    //   cursor: "pointer",
                    //   color: "white",
                    //   fontWeight: "normal",
                    // }}
                    style={{
                      backgroundColor: index % 4 >= 2 ? "#828383" : "#D1A68F",
                      fontWeight: selectedText.includes(item) ? "bold" : "normal"
                    }}
                  >
                    {item}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}
