import moment from "moment"
import { useEffect, useState } from "react"
import { DateRangePicker, afterToday } from "rsuite"
export default function Calender({ date, setDate }) {
  const today = new Date()

  // Function to disable dates after today
  const disabledAfterToday = date => {
    return moment(date).isAfter(today, "day")
  }

  return (
    <>
      <DateRangePicker
        placeholder="Select Date Range"
        value={date}
        shouldDisableDate={disabledAfterToday}
        onChange={setDate}
      />
    </>
  )
}
